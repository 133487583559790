import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { FORMALITIES, INTERPOLATIONS } from "../translator/constants";
import { SERVICES } from "../settings/constants";

const useStyles = makeStyles((theme) => ({
  entryInput: {
    width: "300px",
  },
  input: {
    width: "300px",
  },
  hint: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
  },
  gridAccordion: {
    marginTop: "35px",
    paddingRight: "35px",
  },
  accordionHeading: {
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  accordionText: {
    color: theme.palette.grey[500],
  },
  accordionTextField: {
    width: "250px",
    marginTop: "15px",
  },
}));

export const SegmentCreate = (props: any) => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);
  const toggleAccordion = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setExpanded(!isExpanded);
  };

  return (
    <Create {...props}>
      <SimpleForm>
        <h2 className={classes.hint}>
          <InfoIcon /> Segments will get auto generated via translators
        </h2>
        <TextInput
          label="From / Source Entry"
          source="source_entry"
          multiline
          className={classes.entryInput}
        />
        <ReferenceInput
          label="Source Language *"
          source="source_language.id"
          reference="languages"
          sort={{ field: "name", order: "ASC" }}
          filter={{ source: true }}
          validate={[required()]}
          className={classes.input}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>

        <TextInput
          label="To / Entry"
          source="entry"
          multiline
          resettable
          className={classes.entryInput}
        />
        <ReferenceInput
          label="Target Language *"
          source="target_language.id"
          reference="languages"
          sort={{ field: "name", order: "ASC" }}
          filter={{ target: true }}
          validate={[required()]}
          className={classes.input}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>

        <TextInput
          source="path"
          helperText="JSON Path in structure, i.e. $.title"
          className={classes.input}
        />
        <Grid container justify="space-between" style={{ width: "100%" }}>
          <Grid item xs={12} className={classes.gridAccordion}>
            <Accordion variant="elevation" expanded={isExpanded}>
              <AccordionSummary
                expandIcon={
                  <IconButton>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                onClick={toggleAccordion}
              >
                <Typography className={classes.accordionHeading}>
                  Configuration
                </Typography>
                <Typography className={classes.accordionText}>
                  Formality, Interpolation, Service, File
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column">
                  <Grid item>
                    <ReferenceInput
                      label="Corresponding File"
                      source="file.id"
                      reference="files"
                      sort={{ field: "name", order: "ASC" }}
                      className={classes.input}
                    >
                      <SelectInput optionText="name" optionValue="id" />
                    </ReferenceInput>
                  </Grid>

                  <Grid item>
                    <SelectInput
                      source="formality"
                      choices={FORMALITIES}
                      defaultValue="default"
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item>
                    <SelectInput
                      source="interpolation"
                      choices={INTERPOLATIONS}
                      defaultValue="default"
                      label="Interpolation (variables)"
                      className={classes.input}
                    />
                  </Grid>
                  <Grid>
                    <SelectInput
                      label="Translation Service"
                      source="service"
                      defaultValue="DeepL"
                      choices={SERVICES}
                      className={classes.input}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
