import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextareaAutosize,
  makeStyles,
  Theme,
  Hidden,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { httpClient } from "../../authentication/authProvider";
import Loader from "react-loader-spinner";
import { find } from "lodash/fp";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from "react-router-dom";

type Translator = {
  id: number;
  name: string;
  dataformat: string;
  glossary: {
    name: string;
  };
  source_language: {
    name: string;
    language: string;
  };
  target_language: {
    name: string;
    language: string;
  };
  selections: [{ path: string; format: string }];
  formality: "default" | "more" | "less";
  interpolation: "default" | "i18next" | "polyglot" | "i18n" | "icu";
};

const useStyles = makeStyles((theme: Theme) => ({
  textarea: {
    fontFamily: theme.typography.fontFamily,
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      padding: "0 10px",
    },
  },
  language: {
    margin: "0 0 5px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0 5px 0",
    },
    color: "rgba(0, 0, 0, 0.54)",
  },
  icons: {},
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
}));

const apiUrl = process.env.REACT_APP_STRAPI_API;

const initTranslators = () => {
  return httpClient(apiUrl + "/translators");
};

export const Translate = (props: any) => {
  const classes = useStyles();
  // form
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");

  // handling
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmit] = useState(false);
  const [error, setError] = useState("");

  // data
  const [translatorId, setTranslatorId] = useState<number | string>("");
  const [translators, setTranslators] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    // ts-ignore
    initTranslators()
      .then((result: any) => setTranslators(result.json))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    async function handleSubmit() {
      // init
      setSubmit(false);
      setLoading(true);
      setOutput("");

      // Translate request
      httpClient(`${apiUrl}/translators/${translatorId}/translate`, {
        method: "POST",
        body: JSON.stringify({
          text: input,
        }),
      })
        .then((result: any) => {
          setError("");
          if (result.json) {
            // Beautify JSON-result
            setOutput(JSON.stringify(result.json, null, 2));
          } else {
            // Use result body directly for HTML, Markdown output
            setOutput(result.body);
          }
        })
        .catch((e: any) => {
          setError(e.message);
          setOutput("");
        })
        .finally(() => {
          // Track event
          const translatorToTrack = find<Translator>(
            (t) => t.id === translatorId
          )(translators);
          // @ts-ignore
          window.plausible("Translate " + translatorToTrack.dataformat);

          setLoading(false);
          setSubmit(false);
        });
    }
    if (isSubmitted && !isLoading) {
      handleSubmit();
    }
  }, [input, isLoading, isSubmitted, translatorId, translators]);

  const changeTranslator = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setTranslatorId(e.target.value);
  };

  const changeInput = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  const translator = find<Translator>((t) => t.id === translatorId)(
    translators
  );
  let formattedString = "Your Input";
  if (translator) {
    switch (translator.dataformat) {
      case "Markdown": {
        formattedString = "# Markdown Input";
        break;
      }
      case "Properties": {
        formattedString = "key=value";
        break;
      }
      case "YAML": {
        formattedString = "yaml: \r\n    - input";
        break;
      }
      case "JSON": {
        if (translator.selections && translator.selections.length > 0) {
          let keyValue = translator.selections.reduce((result, value) => {
            return {
              ...result,
              [value.path]: value.format,
            };
          }, {});
          formattedString = JSON.stringify(keyValue, null, 2);
        } else {
          formattedString = JSON.stringify({ title: "JSON Input" }, null, 2);
        }

        break;
      }
      case "HTML": {
        formattedString = "<h1>HTML Input</h1>";
        break;
      }
      case "XML": {
        formattedString = "<title>XML Input</title>";
        break;
      }
      case "Text": {
        formattedString = "Text Input";
        break;
      }
    }
  }

  return (
    <>
      <Grid container>
        {translators && translators.length === 0 && (
          <Grid item xs={12}>
            <Alert severity="warning">
              Create a{" "}
              <Link className={classes.a} to="/translators/create">
                Translator
              </Link>{" "}
              first
            </Alert>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <p>
            Choose your translator{" "}
            <Select
              value={translatorId}
              onChange={changeTranslator}
              style={{ width: "250px" }}
              label="Choose your translator"
            >
              {translators &&
                translators.map((t: any) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.name}
                  </MenuItem>
                ))}
            </Select>
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container style={{ marginTop: "10px" }}>
            <Hidden smDown>
              <Grid item>
                <p
                  style={{
                    marginTop: "11px",
                    marginRight: "5px",
                    color: "rgba(0, 0, 0, 0.75)",
                  }}
                >
                  Machine Translation Service:
                </p>
              </Grid>
              <Grid item>
                <img
                  src="logo_DeepL.svg"
                  width="80px"
                  style={{ marginTop: "10px" }}
                  alt="Machine Translation service used DeepL"
                />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} md={6}>
            {translator && (
              <p className={classes.language}>
                {translator.source_language.name}
              </p>
            )}
            <TextareaAutosize
              aria-label="Your Input"
              placeholder={formattedString}
              rowsMin={8}
              onChange={changeInput}
              className={classes.textarea}
            />
            {/*translator && (
                <p className={classes.icons}>
                  Options: 
                  {translator.formality && translator.formality !== "default" && (<>{translator.formality} formal</>)} 
                  {translator.interpolation !== ("default" || undefined || null || "") && (
                    <>
                        {translator.interpolation === "polyglot" ?  "%{ interpolate }" : "{{ interpolate }}"}
                    </>
                  )}
                  {translator.glossary && (<>with glossary {translator.glossary.name}</>)}
                </p>
                  )*/}

            <Button
              onClick={() => setSubmit(true)}
              variant="contained"
              color="primary"
              disabled={!translatorId || input === "" || isSubmitted}
            >
              {isLoading && (
                <>
                  <Loader
                    type="Rings"
                    color="#00BFFF"
                    height={35}
                    width={35}
                    // @ts-ignore
                    style={{ height: "35px" }}
                  />
                  Translating...
                </>
              )}
              {!isLoading && <>Translate Input</>}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <>
              {translator && (
                <p className={classes.language}>
                  {translator.target_language.name}
                </p>
              )}
              <TextareaAutosize
                aria-label="Translation result"
                placeholder="Translation result"
                disabled
                rowsMin={8}
                value={output}
                className={classes.textarea}
              />
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(output);
                }}
                variant="contained"
                color="primary"
                disabled={output === "" || isSubmitted}
              >
                Copy Translation
              </Button>
            </>
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container style={{ marginTop: "10px" }}>
            <Hidden mdUp>
              <Grid item>
                <p
                  style={{
                    marginTop: "11px",
                    marginRight: "5px",
                    color: "rgba(0, 0, 0, 0.75)",
                  }}
                >
                  Machine Translation Service:
                </p>
              </Grid>
              <Grid item>
                <img
                  src="logo_DeepL.svg"
                  width="80px"
                  style={{ marginTop: "10px" }}
                  alt="Machine Translation service used DeepL"
                />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
