import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Translate } from "./TranslateX";
import { Interpolations, TranslatorFormat } from "../../translator/constants";

type TranslateHandlingProps = RouteComponentProps<{
  library?: string;
  format?: string;
}>;

const getFormat = (lib: string): TranslatorFormat => {
  switch (lib) {
    case "symfony":
    case "phparray":
    case "laravel":
    case "yii": {
      return "PHPArray";
    }
    case "properties": {
      return "Properties";
    }
    case "markdown": {
      return "Markdown";
    }
    case "html": {
      return "HTML";
    }
    case "po": {
      return "PO";
    }
    case "json":
    case "formatjs":
    case "linguijs":
    case "vuei18n":
    case "sveltejs":
    case "python":
    case "polyglot":
    case "i18next":
    case "ngxtranslate":
    case "transloco": {
      return "JSON";
    }
    case "yaml":
    case "pythonYAML":
    case "rubyonrails": {
      return "YAML";
    }
    default:
      return "JSON";
  }
};

const getInterpolation = (lib: string): Interpolations => {
  switch (lib) {
    // case "formatjs":
    //case "linguijs":
    //case "vuei18n":
    //case "sveltejs":
    //case "yii": {
    //  {  }
    //  return "i18n";
    //}
    case "vuei18n": {
      return "i18n";
    }

    case "formatjs":
    case "linguijs":
    case "symfony":
    case "sveltejs":
    case "yii": {
      // A little bit different syntax for yii formatting
      // ICU Messages { variable, options }
      return "icu";
    }

    case "python":
    case "pythonYAML":
    case "polyglot":
    case "rubyonrails": {
      // %{  }
      return "polyglot";
    }

    case "i18next":
    case "ngxtranslate":
    case "transloco": {
      // {{ }}
      return "i18next";
    }
    case "template": {
      // ${ }
      return "ruby";
    }
    case "laravel":
    case "markdown": {
      // :variable and emoji's :smile:
      return "laravel";
    }
    case "po":
    case "html": {
      return "none";
    }

    default: {
      return "default";
    }
  }
};

export const TranslateHandling = (props: TranslateHandlingProps) => {
  const { library } = props.match.params;

  const lib = library ? library : "JSON";

  const format = getFormat(lib);
  const interpolation = getInterpolation(lib);

  return <Translate format={format} interpolation={interpolation} />;
};
