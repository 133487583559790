import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  ReferenceInput,
  ReferenceArrayInput,
  required,
} from "react-admin";
import { FORMALITIES, FORMATS, INTERPOLATIONS } from "../translator/constants";

export const FileCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />

      <TextInput source="filePath" />

      <SelectInput
        source="dataformat"
        choices={FORMATS}
        validate={[required()]}
        defaultValue="JSON"
      />

      <ReferenceInput
        label="Source Language *"
        source="source_language.id"
        reference="languages"
        sort={{ field: "name", order: "ASC" }}
        filter={{ source: true }}
        validate={[required()]}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <ReferenceArrayInput
        label="Target Languages *"
        source="target_languages"
        reference="languages"
        sort={{ field: "name", order: "ASC" }}
        filter={{ target: true }}
        validate={[required()]}
      >
        <SelectArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>

      <SelectInput
        source="interpolation"
        choices={INTERPOLATIONS}
        defaultValue="default"
        label="Interpolation (variables)"
      />

      <SelectInput
        source="formality"
        choices={FORMALITIES}
        defaultValue="default"
      />
    </SimpleForm>
  </Create>
);
