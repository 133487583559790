import { GET_ONE, UPDATE } from "react-admin";
import { httpClient } from '../authentication/authProvider'

// A function decorating a dataProvider for handling user profiles
const handleUserProfile = (dataProvider: any) => (verb: string, resource: string, params: any) => {
  // I know I only GET or UPDATE the profile as there is only one for the current user
  // To showcase how I can do something completely different here, I'll store it in local storage
  // You can replace this with a customized fetch call to your own API route, too
  if (resource === "profile") {
    if (verb === GET_ONE) {
      // Get user data
      return httpClient(process.env.REACT_APP_STRAPI_API + "/users/me", {
        method: "GET"
      })
        .then(({ json }: any) => {
          return { data: {
            ...json,
            id: "profile",
            // Save real Id of user for future update
            tempId: json.id
          }}
        })
        .catch((e: any) => {
          // No profile yet, return a default one
          // It's important that I send the same id as requested in params.
          // Indeed, react-admin will verify it and may throw an error if they are different
          return {data: { id: "profile" }}
        })
    }

    if (verb === UPDATE) {
      console.log(JSON.stringify(params.data));
      const { tempId, passwordRepeat, ...data } = params.data;

      return httpClient(
        process.env.REACT_APP_STRAPI_API + `/users/${tempId}`,
        {
          method: "PUT",
          body: JSON.stringify({
            ...data,
            id: tempId
          }),
        }
      ).then(({ json }: any) => {
        return { data: {
          ...json,
          id: "profile",
          tempId: json.id
        }}
      });
    }
  }

  // Fallback to the dataProvider default handling for all other resources
  return dataProvider(verb, resource, params);
};

export default handleUserProfile
