import { fetchUtils } from "react-admin";
import axios from "axios";
// import type { AuthProvider } from "react-admin";

type LoginInfo = {
  username: string;
  password: string;
};

export const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

/*
export const httpClient = (url: string, options: any = null) => {
  const token = localStorage.getItem("token");
  if (!options) {
    const defaultHeaders = {
      Accept: "application/json",
    };
    if (token) {
      options = {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${token}`,
        },
      };
    } else {
      options = { headers: defaultHeaders };
    }
  }
  console.log(JSON.stringify(options));
  return fetchUtils.fetchJson(url, options);
  // return fetchUtils.fetchJson(url, options);
};
*/

export const authProvider = {
  login: ({ username, password }: LoginInfo) => {
    /*
    const request = new Request(
      process.env.REACT_APP_STRAPI_API + "/auth/local",
      {
        method: "POST",
        body: JSON.stringify({ identifier: username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    */
    const loginPath = process.env.REACT_APP_STRAPI_API + "/auth/local";

    return axios
      .post(
        loginPath,
        { identifier: username, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.data;
      })
      .then(({ user, jwt }) => {
        // console.log("Authenticated as " + JSON.stringify(jwt));
        localStorage.setItem("token", jwt);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    if (window && window.location && process.env.REACT_APP_FRONTEND_LOGIN) {
      window.location.replace(process.env.REACT_APP_FRONTEND_LOGIN);
    }

    // this will not be called
    return Promise.resolve();
  },
  async checkAuth() {
    const token = localStorage.getItem("token");
    if (token) {
      const {
        json: { email },
      } = await httpClient(process.env.REACT_APP_STRAPI_API + "/users/me");

      // Check if it's a valid token
      return email ? Promise.resolve() : Promise.reject();
    }

    // False auth
    return Promise.reject();
  },
  checkError: (error: any) => {
    console.log("Error in Checkerror " + JSON.stringify(error));
    /*
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    */

    return Promise.resolve(error);
  },
  getPermissions: (params: any) => {
    // Check if the email is validated
    return httpClient(process.env.REACT_APP_STRAPI_API + "/users/me").then(
      (response: any) => {
        // Confirmed, email verified user
        if (response.json && response.json.confirmed) {
          return Promise.resolve({
            email: response.json.email,
            confirmed: response.json.confirmed,
          });
        }
        return Promise.resolve({
          email: response.json.email,
          confirmed: false,
        });
      }
    );
  },
  // ...
};
