import merge from "lodash/merge";
import raEnglishMessages from "ra-language-english";

import polyglotI18nProvider from "ra-i18n-polyglot";

const englishMessages = {
  ra: {},
  resources: {
    glossaries: {
      empty: "You have not created a glossary yet.",
      invite:
        "Glossaries are optional. You can use them to further improve your localization/translation results.",
    },
    segments: {
      invite:
        "Segments are automatically created during the translation process. You can edit segments and adapt translations here (online) or locally (CLI) and receive updated translations anytime. ",
    },
  },
};

const messages = merge(raEnglishMessages, englishMessages);

const i18nProvider = polyglotI18nProvider(
  // @ts-ignore
  (locale) => messages,
  "en" // Default locale
);

export default i18nProvider;
