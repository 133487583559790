import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userLogout } from "react-admin";
import { Theme, useMediaQuery } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";
import AccountIcon from "@material-ui/icons/PermIdentity";

const sanitizeRestProps = ({
  data,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  id,
  loading,
  loaded,
  saving,
  resource,
  title,
  version,
  match,
  location,
  history,
  options,
  locale,
  permissions,
  undoable,
  successMessage,
  translate,
  ...rest
}: any) => rest;

const MyLogoutButton = ({ userLogout, ...rest }: any) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return isSmall ? (
    <>
      <MenuItem component={Link} to="/profile">
        <AccountIcon />
        Change Password
      </MenuItem>
      <MenuItem onClick={userLogout} {...sanitizeRestProps(rest)}>
        <ExitIcon /> Logout
      </MenuItem>
    </>
  ) : (
    <>
      <MenuItem component={Link} to="/profile">
        <AccountIcon style={{ marginRight: "8px" }} /> Change Password
      </MenuItem>
      <MenuItem onClick={userLogout} {...sanitizeRestProps(rest)}>
        <ExitIcon style={{ marginRight: "10px" }} /> Logout
      </MenuItem>
    </>
  );
};

const redirectTo = process.env.REACT_APP_FRONTEND_LOGIN;
const myCustomUserLogout = () => userLogout(redirectTo);
export default connect(undefined, { userLogout: myCustomUserLogout })(
  MyLogoutButton
);
