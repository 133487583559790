import React from "react";
import { Link } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typo from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
  ol: {
    lineHeight: "1.85rem",
  },
  inlineIcon: {
    fontSize: "1.2rem",
    verticalAlign: "text-bottom",
  },
  header: {
    marginBottom: "0.5rem",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
    },
  },
  cardRecommend: {
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
      marginTop: "10px",
    },
  },
  cardCLI: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
    },
  },
}));

const SubscribedSuccessPage = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item md={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typo variant="h5" className={classes.header}>
                Thanks for subscribing
              </Typo>
              <Typo variant="body1">
                Quick info in case you've reached your usage quota while
                translating a file before upgrading: <br />
                <br />
                You can simply enter the whole file into the{" "}
                <Link to="/translatorsx">online translator</Link> again and
                translate it. <br />
                If you use the <strong>CLI</strong> just call `simpleen
                translate` again.
                <br />
                <br />
                Segments that have already been translated into the previously
                selected target language will not be deducted from your quota
                again.
              </Typo>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SubscribedSuccessPage;
