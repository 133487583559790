import React from "react";
import { List, Datagrid, TextField } from "react-admin";

export const TranslatorList = (props: any) => (
  <List sort={{ field: "name", order: "ASC" }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <TextField source="name" />
      <TextField source="dataformat" />

      <TextField label="Glossary" source="glossary.name" />

      <TextField label="Target" source="target_language.language" />

      <TextField label="Source" source="source_language.language" />
    </Datagrid>
  </List>
);
