import React from "react";
import { Title } from "react-admin";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Link } from "react-admin";
import GlossaryIcon from "@material-ui/icons/MenuBook";

const useStyles = makeStyles((theme) => ({
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
  inlineIcon: {
    fontSize: "1.2rem",
    verticalAlign: "text-bottom",
  },
  header: {
    marginBottom: "0.5rem",
  },
}));

const NoCode = () => {
  const classes = useStyles();
  return (
    <Card>
      <Title title="Nocode workflow" />
      <CardContent>
        <Typography variant="h5" className={classes.header}>
          Integromat Integration
        </Typography>
        You can use your Simpleen account to add translations to your Integromat
        scenarios. It works with different formats like HTML or Markdown. <br />
        <a
          href="https://www.integromat.com/en/integrations/simpleen-translation?utm_medium=partner&utm_source=simpleentranslation&utm_campaign=simpleen-translation-partner-program"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.a}
        >
          Visit Integromat
        </a>{" "}
        to configure your scenario. You can still add a custom glossary for
        adaption of your results. <br />
        <br />
        <Typography variant="h5" className={classes.header}>
          Zapier Integration
        </Typography>
        You can use your Simpleen account to integrate translations into your
        Zaps. It works with different formats like HTML or Markdown. <br />
        <a
          href="https://zapier.com/apps/simpleen-translation/integrations"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.a}
        >
          Visit Zapier
        </a>{" "}
        to configure your Zaps.
        <br />
        <br />
        <img
          src="./IllustrationIntegromatZapier.png"
          alt="Integromat and Zapier for translation"
          style={{ display: "block", margin: "0 auto", width: "450px" }}
        />{" "}
        <p style={{ textAlign: "center" }}>
          For the usage of Integromat and Zapier you can create your{" "}
          <Link to="/glossaries">
            custom <GlossaryIcon className={classes.inlineIcon} /> glossary.{" "}
          </Link>{" "}
        </p>
      </CardContent>
    </Card>
  );
};

export default NoCode;
