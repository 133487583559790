import { createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

const myTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0f4c81",
    },
    secondary: {
      main: "#0f4c81",
    },
    // secondary: {main: "#ffbe40"},
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font for mac then open sans
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Open Sans",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "rgb(10, 53, 90)",
      },
      colorPrimary: {
        backgroundColor: "rgb(10, 53, 90)",
      },
      colorSecondary: {
        backgroundColor: "rgb(10, 53, 90)",
      },
    },
    MuiDrawer: {
      docked: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        minHeight: "100vh",
      },
    },
  },
});

export const theme = myTheme;
