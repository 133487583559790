import React from "react";
import { Route } from "react-router-dom";
import profile from "./admin/profile";
import { Translate } from "./admin/translate/Translate";
import Integration from "./admin/integration/Integration";
import NoCode from "./admin/nocode/NoCode";
import { TranslatorsOverview } from "./admin/translator/next/TranslatorsOverview";
import { TranslateHandling } from "./admin/translate/next/TranslateHandling";
import { TranslatorHandling } from "./admin/translate/next/TranslatorHandling";
import ManagePage from "./pages/ManagePage";
import UpgradePage from "./pages/SubscribePage";
import ManageUpgradePage from "./pages/ManageUpgradePage";
import SubscribedSuccessPage from "./pages/SubscribedSuccessPage";

const Routes = [
  <Route key="profile" path="/profile" component={profile.edit} />,
  <Route
    key="translateOriginal"
    exact
    path="/translate"
    component={Translate}
  />,
  <Route
    key="translateCustom"
    path="/translate-custom/:id"
    component={TranslatorHandling}
  />,
  <Route
    key="translate"
    path="/translate/:library"
    component={TranslateHandling}
  />,

  <Route key="integration" path="/integration" component={Integration} />,
  <Route key="nocode" path="/nocode" component={NoCode} />,
  <Route
    key="translatePreview"
    path="/translatorsx"
    component={TranslatorsOverview}
  />,

  <Route key="upgrade" path="/upgrade" component={UpgradePage} />,
  <Route key="manage" path="/manage" exact component={ManagePage} />,
  <Route
    key="upgradeManage"
    path="/manageUpgrade"
    exact
    component={ManageUpgradePage}
  />,
  <Route
    key="subscribedSuccess"
    path="/subscribed-successful"
    component={SubscribedSuccessPage}
  />,
];

export default Routes;
