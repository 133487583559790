import React, { FunctionComponent } from "react";
import { useDropzone, DropEvent, FileRejection } from "react-dropzone";

type DropAreaProps = {
  noClick: boolean;
  onDropAccepted: <T extends File>(files: T[], event: DropEvent) => void;
  onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;
};

const MyDropArea: FunctionComponent<DropAreaProps> = (props) => {
  const { getRootProps, getInputProps } = useDropzone({
    noClick: props.noClick,
    accept: [
      "application/json",
      "application/x-yaml",
      "application/markdown",
      "text/yaml",
      "text/x-gettext-translation",
      "text/x-java-properties",
      "text/plain",
      "text/markdown",
      "text/x-markdown",
      "text/html",
      ".md",
      ".markdown",
      ".mdx",
    ],
    multiple: false,
    onDropAccepted: props.onDropAccepted,
    onDropRejected: props.onDropRejected,
  });

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })} tabIndex={-1}>
        <input {...getInputProps()} />
        {props.children}
      </div>
    </section>
  );
};

export default MyDropArea;
