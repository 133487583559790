import React from "react";
import { Layout } from "react-admin";
import MyAppBar from "./AppBar";
import MyMenu from "./Menu";
import MyNotification from "./Notification";

const MyLayout = (props: any) => (
  <Layout
    {...props}
    appBar={MyAppBar}
    menu={MyMenu}
    notification={MyNotification}
  />
);

export default MyLayout;
