import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Translate } from "./TranslateX";
import { Translator } from "../../translator/constants";
import { httpClient } from "../../../authentication/authProvider";
import Loader from "react-loader-spinner";

type TranslateHandlingProps = RouteComponentProps<{
  id?: string;
}>;

const apiUrl = process.env.REACT_APP_STRAPI_API;

const initTranslator = (id: string) => {
  return httpClient(apiUrl + `/translators/${id}`);
};

export const TranslatorHandling = (props: TranslateHandlingProps) => {
  const { id } = props.match.params;

  const [translator, setTranslator] = useState<Translator | undefined>(
    undefined
  );
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // ts-ignore
    initTranslator(id as string)
      .then((result: any) => setTranslator(result.json))
      .finally(() => setLoading(false));
  }, [id]);

  if (isLoading) {
    return (
      <Loader
        type="Rings"
        color="#00BFFF"
        height={35}
        width={35}
        // @ts-ignore
        style={{ height: "35px" }}
      />
    );
  }

  if (translator) {
    return (
      <Translate
        key={translator.id}
        format={translator.dataformat}
        interpolation={translator.interpolation}
        formality={translator.formality}
        glossary={translator.glossary ? translator.glossary.id : ""}
        sourceLanguage={translator.source_language}
        targetLanguage={translator.target_language}
        selections={translator.selections}
      />
    );
  } else {
    return <p>Custom translator not found</p>;
  }
};
