import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import mailgo from "mailgo";
import { Prices } from "../components/Prices";
import { Redirect, useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

// Configure mailgo for mailto links
mailgo({ showFooter: false });

const ManageUpgradePage = () => {
  const history = useHistory();

  const [usageData, setUsageData] = useState<{ plan: any; usage: any }>();
  const [userData, setUserData] = useState<{ id: string; email: string }>();
  const [error, setError] = useState("");
  const dataProvider = useDataProvider();

  useEffect(() => {
    // Request plan & user data
    const requestUsageInfo = async () => {
      try {
        const { data } = await dataProvider.getOne("usages", { id: "info" });
        setUsageData(data);
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    };

    const requestUserInfo = async () => {
      try {
        const { data } = await dataProvider.getOne("users", { id: "me" });
        setUserData(data);
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    };

    requestUsageInfo();
    requestUserInfo();
    // eslint-disable-next-line
  }, []);

  // const classes = useStyles();

  if (!usageData || !userData) return null;

  const { plan } = usageData;

  const upDowngradeProduct = (
    product: number,
    userId: string,
    email: string
  ) => (event: any) => {
    dataProvider
      .create("plans/change", { data: { planId: product } })
      .then(() => history.push("/dashboard"))
      .catch((e: any) => {
        if (e && e.message) {
          setError(e.message);
        } else {
          history.push("/manage");
        }
      });
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}

      {plan.isDefault && <Redirect to="/upgrade" />}

      {!plan.isDefault && (
        <Prices buy={upDowngradeProduct} user={userData} currentPlan={plan} />
      )}
    </>
  );
};

export default ManageUpgradePage;
