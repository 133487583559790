import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { TranslatorFormat } from "../../admin/translator/constants";
import { useQuery } from "react-admin";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.54)",
  },
  text: {
    textAlign: "center",
  },
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
}));

export const PriceEstimator = ({
  plans,
}: {
  plans: { title: string; maxSegments: number }[];
}) => {
  const classes = useStyles();

  const previous30days = new Date();
  previous30days.setDate(previous30days.getDate() - 30);

  const { data, loading, error } = useQuery({
    type: "getList",
    resource: "files",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "created_at", order: "DESC" },
      filter: {
        created_at_gt: previous30days.toISOString().slice(0, 10),
      },
    },
  });

  if (loading || error) {
    return null;
  }

  const relevantFormats: TranslatorFormat[] = [
    "Markdown",
    "HTML",
    "PHPArray",
    "PO",
    "Properties",
    "JSON",
    "YAML",
  ];
  const total = data.reduce((t: number, val: any) => {
    if (val && val.cntSegments) {
      if (relevantFormats.includes(val.dataformat)) {
        const multiplier =
          val.target_languages && val.target_languages.length > 0
            ? val.target_languages.length
            : 1;
        return t + val.cntSegments * multiplier;
      }
    }
    return t;
  }, 0);

  if (total === 0) {
    return null;
  }

  let recommendedPlan = plans.reduce(
    (plan, currentPlan) => {
      if (
        currentPlan &&
        // Has selected plan already enough to cover total
        total > plan.maxSegments &&
        // CurrentPlan has enough for total?
        total < currentPlan.maxSegments
      ) {
        return currentPlan;
      }
      return plan;
    },
    { maxSegments: 0, title: "Starter Plan" }
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.mainTitle}>
          You currently need a {recommendedPlan.title} Plan
        </Typography>
        <Typography
          variant="body1"
          className={classes.text}
          style={{ paddingTop: "1.5rem" }}
        >
          <strong>
            Our suggestion is based on all files you have provided so far
          </strong>
        </Typography>
        {data.map((file: any) => {
          const cntTargetLanguages = file.target_languages
            ? file.target_languages.length
            : 1;
          return (
            <p className={classes.text}>
              <Link
                to={{
                  pathname: "/segments",
                  search: `filter=${JSON.stringify({ file: file.id })}`,
                }}
                className={classes.a}
              >
                {file.name}
              </Link>{" "}
              - {file.cntSegments} Text Segments into {cntTargetLanguages}{" "}
              Language{cntTargetLanguages > 1 ? "s" : null}
            </p>
          );
        })}
        <p className={classes.text}>
          <strong>Total: {total} Text Segments</strong>
        </p>
      </Grid>
    </Grid>
  );
};

export default PriceEstimator;
