import React from "react";
import { List, Datagrid, TextField, FunctionField } from "react-admin";

export const FileList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="dataformat" />
      <TextField source="interpolation" />
      <TextField source="formality" />

      <TextField label="Source Language" source="source_language.language" />

      <FunctionField
        label="Target Languages"
        render={(record: any) => {
          if (
            record &&
            record.target_languages &&
            record.target_languages.length > 0
          ) {
            return record.target_languages
              .map((t: any) => t.language)
              .join(", ");
          }
          return "";
        }}
      />

      <FunctionField
        label="Cached Segments"
        render={(record: any) => record?.segments?.length}
      />
    </Datagrid>
  </List>
);
