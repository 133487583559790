import React from "react";
import { Admin, Resource, ShowGuesser } from "react-admin";
import { Provider } from "react-redux";
import { createHashHistory } from "history";
import strapiDataProvider from "ra-strapi-rest";
import i18nProvider from "./components/i18nProvider";
import handleUserProfile from "./decorators/handleUserProfile";
import { GlossaryCreate, GlossaryEdit, GlossaryList } from "./admin/glossary";
import {
  TranslatorCreate,
  TranslatorEdit,
  TranslatorList,
} from "./admin/translator";
import { FileCreate, FileEdit, FileList } from "./admin/file";
import { SegmentCreate, SegmentEdit, SegmentList } from "./admin/segment";
// import { LanguageList } from "./admin/language";
import customRoutes from "./customRoutes";
// import { SettingsCreate, SettingsEdit, SettingsList } from "./admin/settings";
import { authProvider, httpClient } from "./authentication/authProvider";
import { theme } from "./theme";
import MyLayout from "./components/Layout";
import MyLogoutButton from "./components/LogoutButton";
import Dashboard from "./components/Dashboard";
import { createAdminStore } from "react-admin";

const dataProvider = handleUserProfile(
  strapiDataProvider(process.env.REACT_APP_STRAPI_API, httpClient)
);

const history = createHashHistory();

const App = () => {
  return (
    <Provider
      store={createAdminStore({
        authProvider,
        dataProvider,
        history,
      })}
    >
      <Admin
        title="Simpleen"
        layout={MyLayout}
        theme={theme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        history={history}
        logoutButton={MyLogoutButton}
        customRoutes={customRoutes}
        dashboard={Dashboard}
        disableTelemetry
      >
        {(permissions: { confirmed: boolean; email: string }) =>
          // Restrict access for users that are not registered

          permissions.confirmed
            ? [
                <Resource
                  name="translators"
                  create={TranslatorCreate}
                  list={TranslatorList}
                  edit={TranslatorEdit}
                  show={ShowGuesser}
                />,
                <Resource
                  name="files"
                  create={FileCreate}
                  edit={FileEdit}
                  list={FileList}
                  show={ShowGuesser}
                />,
                <Resource
                  name="segments"
                  create={SegmentCreate}
                  edit={SegmentEdit}
                  list={SegmentList}
                  show={ShowGuesser}
                />,
                <Resource
                  name="glossaries"
                  list={GlossaryList}
                  create={GlossaryCreate}
                  edit={GlossaryEdit}
                  show={ShowGuesser}
                />,
                <Resource name="languages" />,
                <Resource name="tokens" />,
                <Resource name="profile" />,
                <Resource name="translate" />,
              ]
            : [<Resource name="languages" />]
        }
      </Admin>
    </Provider>
  );
};

export default App;
