import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  makeStyles,
  Theme,
  Accordion,
  AccordionDetails,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { httpClient } from "../../../authentication/authProvider";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  Language,
  Glossary,
  Interpolations,
  TranslatorFormat,
  Formality,
  FORMALITIES,
  FORMATS,
  INTERPOLATIONS,
  Selection,
} from "../../translator/constants";
import Typography from "@material-ui/core/Typography/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import FileCopy from "@material-ui/icons/FileCopy";
import DownloadIcon from "@material-ui/icons/GetApp";
import UploadIcon from "@material-ui/icons/Publish";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import { saveAs } from "file-saver";
import Hidden from "@material-ui/core/Hidden/Hidden";
import { useDataProvider } from "react-admin";
import { Link } from "ra-ui-materialui";

import { Autocomplete } from "@material-ui/lab";
import DropArea from "../../../components/DropArea";
import MonacoEditor, { useMonaco } from "@monaco-editor/react";
import { registerPHPSnippetLanguage } from "./MonacoEditor/phpsnippet";

type AutoCompleteReason =
  | "create-option"
  | "select-option"
  | "remove-option"
  | "blur"
  | "clear";

type TranslateProps = {
  format: TranslatorFormat;
  interpolation: Interpolations;
  glossary?: string;
  formality?: Formality;
  sourceLanguage?: Language;
  targetLanguage?: Language;
  selections?: Selection[];
};

const useStyles = makeStyles((theme: Theme) => ({
  textarea: {
    fontFamily: theme.typography.fontFamily,
    width: "95%",
    "&::placeholder": {
      fontSize: "1.25rem",
      paddingTop: "55px",
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      padding: "0 10px",
    },
  },
  inputTextarea: {
    fontFamily: theme.typography.fontFamily,
    width: "95%",
    marginBottom: "20px",
  },
  inputPlaceholder: {
    position: "relative",
    top: -275,
    fontSize: "1.15rem",
    width: "95%",
    pointerEvents: "none",
    // same as input text area
    // height: "-480px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "white",
  },
  language: {
    margin: "0 0 5px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0 5px 0",
    },
    color: "rgba(0, 0, 0, 0.54)",
  },
  icons: {},
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
  gridAccordion: {
    marginTop: "35px",
    paddingRight: "35px",
  },
  accordionHeading: {
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  accordionText: {
    color: theme.palette.grey[500],
  },
  accordionTextField: {
    width: "250px",
    marginTop: "15px",
  },
}));

const apiUrl = process.env.REACT_APP_STRAPI_API;

/* Check out when this is natively supported by Monaco Editor
const isDataformatRtl = (dataformat: TranslatorFormat) => {
  return dataformat === "Markdown" || dataformat === "HTML";
};
*/

const initSourceLanguages = () => {
  return httpClient(
    apiUrl + "/languages?source=true&_sort=name:ASC&_limit=125"
  );
};

const initTargetLanguages = () => {
  return httpClient(
    apiUrl + "/languages?target=true&_sort=name:ASC&_limit=125"
  );
};

const initGlossaries = () => {
  return httpClient(apiUrl + "/glossaries?_sort=name:ASC");
};

const getFileInfo = (
  format: TranslatorFormat
): { type: string; extension: string } => {
  switch (format) {
    case "HTML": {
      return {
        type: "application/xhtml+xml;charset=utf-8",
        extension: "html",
      };
    }
    case "JSON": {
      return {
        type: "application/json;charset=utf-8",
        extension: "json",
      };
    }
    case "Markdown": {
      return {
        extension: "md",
        type: "text/markdown;charset=utf-8",
      };
    }
    case "PHPArray": {
      return {
        extension: "php",
        type: "application/x-httpd-php;charset=utf-8",
      };
    }
    case "Properties": {
      return {
        extension: "properties",
        type: "text/x-java-properties;charset=utf-8",
      };
    }
    case "PO": {
      return {
        extension: "po",
        type: "text/plain;charset=utf-8",
      };
    }
    case "YAML": {
      return {
        extension: "yml",
        type: "application/x-yaml;charset=uft-8",
      };
    }
  }
};

const getSyntaxName = (format: TranslatorFormat) => {
  switch (format) {
    case "HTML": {
      return "html";
    }
    case "JSON": {
      return "json";
    }
    case "Markdown": {
      return "markdown";
    }
    case "PHPArray": {
      return "php-snippet";
    }
    case "Properties": {
      return "ini";
    }
    case "PO": {
      // The best I've found # comment
      return "perl";
    }
    case "YAML": {
      return "yaml";
    }
  }
};

function isDeepL(
  sourceLanguage: string | undefined,
  targetLanguage: string | undefined
) {
  const sourceDeepl = [
    "BG",
    "CS",
    "DA",
    "DE",
    "EL",
    "EN",
    "ES",
    "ET",
    "FI",
    "FR",
    "HU",
    "IT",
    "JA",
    "LT",
    "LV",
    "NL",
    "PL",
    "PT",
    "RO",
    "RU",
    "SK",
    "SL",
    "SV",
    "ZH",
  ];

  const targetDeepl = [
    "BG",
    "CS",
    "DA",
    "DE",
    "EL",
    "EN-GB",
    "EN-US",
    "ES",
    "ET",
    "FI",
    "FR",
    "HU",
    "IT",
    "JA",
    "LT",
    "LV",
    "NL",
    "PL",
    "PT-PT",
    "PT-BR",
    "RO",
    "RU",
    "SK",
    "SL",
    "SV",
    "ZH",
  ];
  if (sourceLanguage && targetLanguage) {
    return (
      sourceDeepl.includes(sourceLanguage) &&
      targetDeepl.includes(targetLanguage)
    );
  }
  return true;
}

const defaultLanguage = {
  language: "EN",
  name: "English",
  id: "1",
  isRtl: false,
};

export const Translate = (props: TranslateProps) => {
  const selections =
    props.selections && props.selections.length > 0
      ? JSON.stringify(props.selections)
      : null;

  const classes = useStyles();
  // form
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");

  const [glossary, setGlossary] = useState<string>(
    props.glossary ? props.glossary : ""
  );
  const [format, setFormat] = useState(props.format);
  const [interpolation, setInterpolation] = useState(props.interpolation);
  const [formality, setFormality] = useState<Formality>(
    props.formality ? props.formality : "default"
  );
  const [sourceLanguage, setSourceLanguage] = useState<Language | undefined>(
    props.sourceLanguage ? props.sourceLanguage : defaultLanguage
  );
  const [sourceLanguages, setSourceLanguages] = useState<Language[]>([]);
  const [glossaries, setGlossaries] = useState<Glossary[]>([]);

  const [targetLanguage, setTargetLanguage] = useState<Language | undefined>(
    props.targetLanguage ? props.targetLanguage : undefined
  );
  const [targetLanguages, setTargetLanguages] = useState<Language[]>([]);

  // handling
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmit] = useState(false);
  const [showHint, setShowHint] = useState(true);
  const [error, setError] = useState("");
  const [isExpanded, setExpanded] = useState(false);
  const [cacheOutput, setCacheOutput] = useState<{
    [targetLanguage: string]: any;
  }>({});
  const [usageData, setUsageData] = useState<{ plan: any; usage: any }>();
  const dataProvider = useDataProvider();

  const monaco = useMonaco();

  // Customize monaco editor
  useEffect(() => {
    if (monaco) {
      // Register language php-snippet
      registerPHPSnippetLanguage(monaco.languages);
      // JSON5 handling: Allow comments in JSON and remove validation because single quotes and no quotes can be used as keys
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        allowComments: true,
        validate: false,
      });

      return () => {};
    }
    return () => {};
  }, [monaco]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // ts-ignore
    initGlossaries()
      .then((result: any) => setGlossaries(result.json))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    // ts-ignore
    initSourceLanguages()
      .then((result: any) => setSourceLanguages(result.json))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    // ts-ignore
    initTargetLanguages()
      .then((result: any) => setTargetLanguages(result.json))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    // After each submit request usage
    const requestUsageInfo = async () => {
      try {
        const { data } = await dataProvider.getOne("usages", { id: "info" });
        setUsageData(data);
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    };
    if (output !== "") {
      requestUsageInfo();
    }
    // eslint-disable-next-line
  }, [output]);

  useEffect(() => {
    async function handleSubmit() {
      // init
      setSubmit(false);
      setLoading(true);
      setOutput("");
      setShowHint(true);

      // Translate request
      httpClient(`${apiUrl}/translate`, {
        method: "POST",
        body: JSON.stringify({
          format,
          glossary: glossary ? glossary : undefined,
          interpolation,
          formality,
          source_language: sourceLanguage?.language,
          target_language: targetLanguage?.language,
          text: input,
          selections,
        }),
      })
        .then((result: any) => {
          setError("");
          // Set formatted value
          if (result.json) {
            // Beautify JSON-result
            setOutput(JSON.stringify(result.json, null, 2));
            // Cache results
            if (targetLanguage) {
              setCacheOutput({
                ...cacheOutput,
                [targetLanguage.language]: JSON.stringify(result.json, null, 2),
              });
            }
          } else {
            // Use result body directly for HTML, Markdown output
            setOutput(result.body);
            // Cache results
            if (targetLanguage) {
              setCacheOutput({
                ...cacheOutput,
                [targetLanguage.language]: result.json,
              });
            }
          }
        })
        .catch((e: any) => {
          setError(e.message);
          setOutput("");
        })
        .finally(() => {
          // @ts-ignore
          window.plausible("Translate " + format);

          setLoading(false);
          setSubmit(false);
        });
    }
    if (isSubmitted && !isLoading) {
      handleSubmit();
    }
  }, [
    input,
    isLoading,
    isSubmitted,
    sourceLanguage,
    targetLanguage,
    glossary,
    format,
    interpolation,
    formality,
    cacheOutput,
    selections,
  ]);

  const changeInput = (value: string | undefined) => {
    // e.preventDefault();
    if (value) {
      setInput(value);
    } else {
      setInput("");
    }
    setError("");
    // Invalidate cache if input changed
    setOutput("");
    setCacheOutput({});
  };

  const toggleAccordion = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setExpanded(!isExpanded);
  };

  const changeGlossary = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setGlossary(e.target.value);
  };

  const changeFormat = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setFormat(e.target.value);
  };

  const changeSourceLanguage = (
    e: React.ChangeEvent<any>,
    value: Language | null,
    reason: AutoCompleteReason
  ) => {
    e.preventDefault();
    if (value) {
      setSourceLanguage(value);
      // Invalidate cache
      setCacheOutput({});
      setOutput("");
    }
    if (reason === "clear") {
      setSourceLanguage(defaultLanguage);
    }
  };

  const changeTargetLanguage = (
    e: React.ChangeEvent<any>,
    value: Language | null,
    reason: AutoCompleteReason
  ) => {
    e.preventDefault();
    if (value) {
      setTargetLanguage(value);
      if (cacheOutput && cacheOutput[value.language]) {
        setOutput(cacheOutput[value.language]);
      } else {
        setOutput("");
      }
    }
    if (reason === "clear") {
      setTargetLanguage(undefined);
    }
  };

  const changeInterpolation = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setInterpolation(e.target.value);
  };
  const changeFormality = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setFormality(e.target.value);
  };

  let formattedString = "Input data";
  if (format) {
    switch (format) {
      case "Markdown": {
        formattedString = "# Input Markdown or drop file here";
        break;
      }
      case "Properties": {
        formattedString = "Input Java properties or drop file here";
        break;
      }
      case "PHPArray": {
        formattedString = "['key' => 'Input associative PHP-Array']";
        break;
      }
      case "PO": {
        formattedString = "key=Input PO data or drop file here";
        break;
      }
      case "YAML": {
        formattedString = "Input Yaml or drop file here";
        break;
      }
      case "JSON": {
        /*
        if (
          translatorConfig.selections &&
          translatorConfig.selections.length > 0
        ) {
          let keyValue = translatorConfig.selections.reduce((result, value) => {
            return {
              ...result,
              [value.path]: value.format,
            };
          }, {});
          formattedString = JSON.stringify(keyValue, null, 2);
        } else {
          formattedString = JSON.stringify({ title: "Input JSON" }, null, 2);
        }*/
        formattedString = JSON.stringify(
          { title: "Input JSON", file: "or drop file here" },
          null,
          2
        );
        break;
      }
      case "HTML": {
        formattedString = "<h1>Input HTML or drop file here</h1>";
        break;
      }
      /*case "XML": {
        formattedString = "<title>Input XML</title>";
        break;
      }*/
    }
  }

  const handleFileDrop = (files: File[]) => {
    if (files && files[0]) {
      const file = files[0];
      const filereader = new FileReader();
      if (filereader) {
        filereader.onloadend = () => {
          if (typeof filereader.result === "string") {
            // Automatically select appropriate Format for mime-type
            if (
              file.type === "application/x-yaml" ||
              file.type === "text/yaml"
            ) {
              setFormat("YAML");
            }
            if (file.type === "application/JSON") {
              setFormat("JSON");
            }
            if (file.type === "text/x-gettext-translation") {
              setFormat("PO");
            }
            if (file.type === "text/x-java-properties") {
              setFormat("Properties");
            }
            if (file.type === "text/markdown") {
              setFormat("Markdown");
            }
            if (file.type === "text/html") {
              setFormat("HTML");
            }
            // Set Input with new data
            setError("");
            setInput(filereader.result);
          }
        };
        filereader.readAsText(files[0], "UTF-8");
      }
    }
  };

  const TranslateInputGrid = (
    <Grid item xs={12} md={6} style={{ paddingTop: "1.5em" }}>
      <Grid container justify="space-between">
        <Grid item>
          <Autocomplete
            autoSelect
            autoHighlight
            onChange={changeTargetLanguage}
            options={targetLanguages}
            getOptionSelected={(option: Language, value) =>
              option.language === targetLanguage?.language
            }
            getOptionLabel={(option) => option.name}
            value={targetLanguage}
            // getOptionLabel={(option) => (option.name ? option.name : "")}
            style={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Translate to"
                variant="standard"
                autoFocus
              />
            )}
            tabIndex={-1}
          />
        </Grid>
        <Grid item>
          <Tooltip
            title={`Save as simpleen-${targetLanguage?.language}.${
              getFileInfo(format).extension
            }`}
            disableFocusListener
            tabIndex={-1}
          >
            <IconButton
              disabled={output === "" || isSubmitted}
              onClick={() => {
                const blob = new Blob([output], {
                  type: getFileInfo(format).type,
                });
                saveAs(
                  blob,
                  `simpleen-${targetLanguage?.language}.${
                    getFileInfo(format).extension
                  }`
                );
              }}
              style={{ marginTop: "10px", marginRight: "10px" }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy to clipboard">
            <IconButton
              disabled={output === "" || isSubmitted}
              onClick={() => {
                navigator.clipboard.writeText(output);
              }}
              style={{ marginTop: "10px", marginRight: "40px" }}
            >
              <FileCopy />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} style={{ paddingTop: "1.5em" }}>
          <Grid container justify="space-between">
            <Grid item>
              <Autocomplete
                autoSelect
                autoHighlight
                onChange={changeSourceLanguage}
                options={sourceLanguages}
                getOptionSelected={(option: Language, value) =>
                  option.language === sourceLanguage?.language
                }
                getOptionLabel={(option) => option.name}
                value={sourceLanguage}
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Translate from"
                    variant="standard"
                  />
                )}
                tabIndex={0}
              />
            </Grid>
            <Grid item>
              <DropArea
                noClick={false}
                onDropAccepted={handleFileDrop}
                onDropRejected={(fileRejections) => {
                  const rejection = fileRejections[0];
                  console.log(
                    rejection.errors.map((e) => e.message).join(", ")
                  );
                  setError("File not supported");
                }}
              >
                <Tooltip title={`Upload/Select File`}>
                  <IconButton
                    tabIndex={-1}
                    style={{ marginTop: "10px", marginRight: "40px" }}
                  >
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              </DropArea>
            </Grid>
          </Grid>

          {/*
          <InputLabel color="primary" id="source-language">
            Translate from
          </InputLabel>
          <Select
            labelId="source-language"
            value={sourceLanguage}
            onChange={changeSourceLanguage}
            style={{ width: "200px" }}
          >
            {sourceLanguages &&
              sourceLanguages.map((t: any) => (
                <MenuItem key={t.id} value={t.language}>
                  {t.name}
                </MenuItem>
              ))}
          </Select>
              */}
        </Grid>

        <Hidden smDown>{TranslateInputGrid}</Hidden>

        <Grid container direction="row">
          <Grid item xs={12} md={6}>
            <DropArea
              noClick={true}
              onDropAccepted={handleFileDrop}
              onDropRejected={(fileRejections) => {
                const rejection = fileRejections[0];
                console.log(rejection.errors.map((e) => e.message).join(", "));
                setError("File not supported");
              }}
            >
              <MonacoEditor
                width="95%"
                height="500px"
                language={getSyntaxName(format)}
                theme="vs-dark"
                value={input}
                options={{
                  tabIndex: 0,
                  lineNumbers: "off",
                  minimap: { enabled: false },
                  scrollbar: {
                    alwaysConsumeMouseWheel: false,
                  },
                }}
                onChange={changeInput}
                className={classes.inputTextarea}

                // editorDidMount={::this.editorDidMount}
              />
              {!input && (
                <div className={classes.inputPlaceholder}>
                  {formattedString}
                </div>
              )}

              {/*
              <TextareaAutosize
                aria-label="Your Input"
                placeholder={formattedString}
                rowsMin={8}
                rowsMax={22}
                onChange={changeInput}
                value={input}
                className={classes.inputTextarea}
                tabIndex={0}
                style={{
                  direction:
                    isDataformatRtl(format) &&
                    sourceLanguage &&
                    sourceLanguage.isRtl
                      ? "rtl"
                      : "ltr",
                }}
              />
              */}
            </DropArea>
            <Button
              onClick={() => setSubmit(true)}
              variant="contained"
              color="primary"
              disabled={input === "" || isSubmitted}
            >
              {isLoading && (
                <>
                  <Loader
                    type="Rings"
                    color="#00BFFF"
                    height={35}
                    width={35}
                    // @ts-ignore
                    style={{ height: "35px" }}
                  />
                  Translating...
                </>
              )}
              {!isLoading && <>Translate Input</>}
            </Button>
          </Grid>
          <Hidden mdUp>{TranslateInputGrid}</Hidden>
          <Grid item xs={12} md={6} style={{ display: "inline-block" }}>
            <>
              {/*
              {translator && (
                <p className={classes.language}>
                  {translator.target_language.name}
                </p>
              )}*/}

              {/* Toolbar */}
              <div
                style={{
                  zIndex: 1000,
                  float: "right",
                  top: "55px",
                  right: "5%",
                }}
              ></div>
              <MonacoEditor
                width="95%"
                height="500px"
                language={getSyntaxName(format)}
                theme="vs-dark"
                value={output}
                options={{
                  tabIndex: 0,
                  lineNumbers: "off",
                  minimap: { enabled: false },
                  readOnly: true,
                  cursorStyle: "block",
                  scrollbar: {
                    alwaysConsumeMouseWheel: false,
                  },
                }}
                className={classes.inputTextarea}

                // onChange={changeOutput}

                // editorDidMount={::this.editorDidMount}
              />
              {!output && (
                <div className={classes.inputPlaceholder}>
                  Translation result
                </div>
              )}
              {/*
              <TextareaAutosize
                aria-label="Translation result"
                placeholder="Translation result"
                disabled
                rowsMin={8}
                rowsMax={22}
                value={output}
                className={classes.textarea}
                style={{
                  direction:
                    isDataformatRtl(format) &&
                    targetLanguage &&
                    targetLanguage.isRtl
                      ? "rtl"
                      : "ltr",
                }}
              />
              */}

              <Button
                onClick={() => {
                  const blob = new Blob([output], {
                    type: getFileInfo(format).type,
                  });
                  saveAs(
                    blob,
                    `simpleen-${targetLanguage?.language}.${
                      getFileInfo(format).extension
                    }`
                  );
                }}
                variant="contained"
                color="primary"
                disabled={output === "" || isSubmitted}
                tabIndex={0}
              >
                Download{" "}
                {usageData &&
                  usageData.usage.segment >= usageData.plan.maxSegment &&
                  "Part"}
              </Button>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(output);
                }}
                variant="contained"
                color="primary"
                disabled={output === "" || isSubmitted}
                style={{ marginLeft: "10px" }}
              >
                Copy
              </Button>
            </>
          </Grid>
        </Grid>

        {error && (
          <Grid item xs={12} style={{ marginTop: "2rem" }}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6}></Grid>

        <Grid item xs={12} md={6}>
          <Grid container style={{ marginTop: "10px" }}>
            <Grid item>
              <p
                style={{
                  marginTop: "11px",
                  marginRight: "5px",
                  color: "rgba(0, 0, 0, 0.75)",
                }}
              >
                Machine Translation Service used:
              </p>
            </Grid>
            <Grid item>
              {isDeepL(sourceLanguage?.language, targetLanguage?.language) && (
                <img
                  src="logo_DeepL.svg"
                  width="80px"
                  style={{ marginTop: "10px" }}
                  alt="Machine Translation service used DeepL"
                />
              )}
              {!isDeepL(sourceLanguage?.language, targetLanguage?.language) && (
                <img
                  width="30px"
                  style={{ marginLeft: "5px", marginTop: "10px" }}
                  src="google_translate_logo.svg"
                  alt="Machine Translation service used Google Translate"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          open={
            showHint &&
            usageData &&
            usageData.usage.segment >= usageData.plan.maxSegment
          }
          onClose={() => setShowHint(false)}
          autoHideDuration={12000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {
            <>
              <Link to="/upgrade">
                <Alert severity="warning">
                  <AlertTitle>You're out of segments</AlertTitle>
                  {usageData && usageData.plan.maxSegment === 100 ? (
                    // User in free plan
                    <>
                      <strong>Subscribe</strong> to one of our paid plans to
                      finish localizing your file.
                    </>
                  ) : (
                    // Upgrade existing plan to user
                    <>
                      <strong>Upgrade</strong> your plan to continue
                      translating.
                    </>
                  )}
                </Alert>
              </Link>
            </>
          }
        </Snackbar>

        <Grid item xs={12} className={classes.gridAccordion}>
          <Accordion variant="elevation" expanded={isExpanded}>
            <AccordionSummary
              expandIcon={
                <IconButton>
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              onClick={toggleAccordion}
            >
              <Typography className={classes.accordionHeading}>
                Optional settings
              </Typography>
              <Typography className={classes.accordionText}>
                Formality, Input Format, Interpolation, Glossary
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid item>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Formality</FormLabel>
                    <RadioGroup
                      aria-label="formality"
                      name="formality"
                      value={formality}
                      onChange={changeFormality}
                      row
                    >
                      {FORMALITIES.map((f) => (
                        <FormControlLabel
                          value={f.id}
                          control={<Radio />}
                          label={f.name}
                          labelPlacement="end"
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  {/*
                  <TextField
                    onChange={changeFormality}
                    value={formality}
                    label="Formal/Informal"
                    className={classes.accordionTextField}
                  >
                    {FORMALITIES.map((formality) => (
                      <MenuItem key={formality.id} value={formality.id}>
                        {formality.name}
                      </MenuItem>
                    ))}
                    </TextField>*/}
                </Grid>

                <Grid item>
                  <TextField
                    value={format}
                    label="Input Format"
                    onChange={changeFormat}
                    className={classes.accordionTextField}
                    select
                  >
                    {FORMATS.map((f) => (
                      <MenuItem disabled={f.disabled} key={f.id} value={f.id}>
                        {f.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item>
                  <TextField
                    value={interpolation}
                    label="Interpolation/Variable"
                    onChange={changeInterpolation}
                    className={classes.accordionTextField}
                    select
                  >
                    {INTERPOLATIONS.map((i) => (
                      <MenuItem disabled={i.disabled} key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item>
                  {glossaries && (
                    <>
                      <TextField
                        onChange={changeGlossary}
                        value={glossary}
                        label="Glossary"
                        // helperText="Customize translation results"

                        className={classes.accordionTextField}
                        select
                      >
                        <MenuItem key="none" value="">
                          No Glossary
                        </MenuItem>
                        {glossaries.map((g) => (
                          <MenuItem key={g.id} value={g.id}>
                            {g.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};
