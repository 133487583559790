import React from "react";
import {
  Edit,
  ArrayInput,
  BooleanInput,
  TabbedForm,
  FormTab,
  TextInput,
  FormDataConsumer,
  required,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import CustomFormIterator from "./components/CustomFormIterator";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: "3px 10px 0 0",
  },
  inputStart: {
    margin: "3px 0 0 0",
  },
  hint: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

export const GlossaryEdit = (props: any) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="General">
          <TextInput source="id" disabled />
          <TextInput source="name" validate={[required()]} />
        </FormTab>

        <FormTab label="Entries">
          <p className={classes.hint}>
            Add customized translations for certain words. Decide to replace
            them before or after translation
          </p>
          <ArrayInput source="entries" label="">
            <CustomFormIterator className="test">
              <TextInput
                source="from"
                label="From"
                validate={[required()]}
                className={classes.inputStart}
              />
              <FormDataConsumer>
                {() => <ArrowIcon style={{ marginTop: "0.75rem" }} />}
              </FormDataConsumer>
              <TextInput
                source="to"
                label="To"
                validate={[required()]}
                className={classes.input}
              />
              <BooleanInput
                source="postTranslate"
                label="Replace after translation"
                className={classes.input}
              />
            </CustomFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Do not translate (Ignored)">
          <p className={classes.hint}>
            Add words you do not wish to be translated
          </p>
          <ArrayInput source="ignored" label="">
            <CustomFormIterator>
              <TextInput
                source="word"
                label="Word"
                validate={[required()]}
                className={classes.input}
              />
            </CustomFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
