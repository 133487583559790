import * as React from "react";
import { usePermissions } from "react-admin";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
// @ts-ignore
import { MenuItemLink } from "react-admin";
import DashboardIcon from "@material-ui/icons/Dashboard";
import IntegrationIcon from "@material-ui/icons/VpnKey";
import NocodeIcon from "@material-ui/icons/Code";
// import FileIcon from "@material-ui/icons/Description";
import SegmentIcon from "@material-ui/icons/TextFields";
import GlossaryIcon from "@material-ui/icons/MenuBook";
import TranslateIcon from "@material-ui/icons/Translate";
import { UsageComponent } from "./Usage";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  usage: {
    marginTop: "50px",
    paddingLeft: "16px",
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

const Menu = ({ onMenuClick, logout }: any) => {
  const { permissions } = usePermissions();
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const classes = useStyles();

  // const resources: any = useSelector(getResources);
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);

  /*
  const filteredResources = resources.filter((resource: any) =>
    ["translators", "glossaries", "settings"].includes(resource.name)
  );*/

  return permissions && permissions.confirmed ? (
    <div>
      {/* DashBoard */}
      <MenuItemLink
        key="dashboard"
        to="/"
        primaryText="Getting Started"
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        exact
        style={{ marginTop: "15px" }}
      />
      {/* Translators */}
      <MenuItemLink
        key={"translatorsx"}
        to={`/translatorsx`}
        primaryText="Translators"
        leftIcon={<TranslateIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {/* Files
      <MenuItemLink
        key={"files"}
        to={`/files`}
        primaryText="Files"
        leftIcon={<FileIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        style={{ paddingLeft: "55px" }}
      /> submenu */}

      {/* Glossaries */}
      <MenuItemLink
        key={"glossaries"}
        to={`/glossaries`}
        primaryText="Glossaries"
        leftIcon={<GlossaryIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      {/* Segments */}
      <MenuItemLink
        key={"segments"}
        to={`/segments`}
        primaryText="Segments"
        leftIcon={<SegmentIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      {/* Integration */}
      <MenuItemLink
        key="integration"
        to="/integration"
        primaryText="Integration"
        leftIcon={<IntegrationIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      {/* NoCode */}
      <MenuItemLink
        key="nocode"
        to="/nocode"
        primaryText="Integromat/Zapier"
        leftIcon={<NocodeIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      {/* Usage */}
      <UsageComponent sidebarIsOpen={open} className={classes.usage} />

      {isXSmall && logout}
    </div>
  ) : (
    <div>
      {/* Dashboard Validate email address */}
      <MenuItemLink
        key="dashboard"
        to="/"
        primaryText="Getting Started"
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        exact
        style={{ marginTop: "15px" }}
      />
    </div>
  );
};

export default Menu;
