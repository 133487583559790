import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import mailgo from "mailgo";
import { Prices } from "../components/Prices";
import { Redirect, useHistory } from "react-router-dom";

/*
const currency_symbols = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};
*/

// Configure mailgo for mailto links
mailgo({ showFooter: false });

const SubscribePage = () => {
  const Paddle: any = (window as any).Paddle;
  const history = useHistory();

  const [usageData, setUsageData] = useState<{ plan: any; usage: any }>();
  const [userData, setUserData] = useState<{ id: string; email: string }>();
  const dataProvider = useDataProvider();

  useEffect(() => {
    // Request plan & user data
    const requestUsageInfo = async () => {
      try {
        const { data } = await dataProvider.getOne("usages", { id: "info" });
        setUsageData(data);
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    };

    const requestUserInfo = async () => {
      try {
        const { data } = await dataProvider.getOne("users", { id: "me" });
        setUserData(data);
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    };

    requestUsageInfo();
    requestUserInfo();
    // eslint-disable-next-line
  }, []);

  if (!usageData || !userData) return null;

  const { plan } = usageData;

  const handleProduct = (product: number, userId: string, email: string) => (
    event: any
  ) => {
    // @ts-ignore
    if (window && window.plausible && typeof window.plausible === "function") {
      // @ts-ignore
      window.plausible("Click Subscribe");
    }

    Paddle.Checkout.open({
      product,
      email,
      passthrough: userId,
      successCallback: (data: any, err: any) => {
        // Redirect to successful subscribed
        history.push("/subscribed-successful");
        Paddle.Spinner.hide();
        return true;
      },
    });
  };

  return (
    <>
      {plan.isDefault && (
        <Prices buy={handleProduct} user={userData} currentPlan={null} />
      )}

      {!plan.isDefault && <Redirect to="/manage" />}
    </>
  );
};

export default SubscribePage;
