import React from "react";
import {
  FormDataConsumer,
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  required,
  SelectInput,
} from "react-admin";
import {
  FORMATS,
  FORMATS_SELECTION,
  FORMALITIES,
  INTERPOLATIONS,
} from "./constants";
import { TranslationHint } from "./TranslationHint";

export const TranslatorEdit = (props: any) => {
  return (
    <>
      <Edit {...props}>
        <SimpleForm redirect="/translatorsx">
          <TextInput source="id" disabled />
          <TextInput source="name" validate={[required()]} />

          <ReferenceInput
            label="Source Language *"
            source="source_language.id"
            reference="languages"
            sort={{ field: "name", order: "ASC" }}
            filter={{ source: true }}
            validate={[required()]}
          >
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>

          <ReferenceInput
            label="Target Language *"
            source="target_language.id"
            reference="languages"
            sort={{ field: "name", order: "ASC" }}
            filter={{ target: true }}
            validate={[required()]}
          >
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>

          <SelectInput
            source="formality"
            choices={FORMALITIES}
            defaultValue="default"
          />

          <SelectInput
            source="interpolation"
            choices={INTERPOLATIONS}
            defaultValue=""
            label="Interpolation (variables)"
          />

          <ReferenceInput
            label="Glossary"
            source="glossary.id"
            reference="glossaries"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>

          <SelectInput
            source="dataformat"
            choices={FORMATS}
            validate={[required()]}
          />

          <FormDataConsumer>
            {({ formData, ...rest }: any) => (
              <>
                {formData.dataformat === "JSON" && (
                  <ArrayInput
                    source="selections"
                    label="Selections (optional - defaults to all with HTML Format - down to 5 levels)"
                  >
                    <SimpleFormIterator>
                      <TextInput
                        label="JSON Path"
                        source="path"
                        validate={[required()]}
                      />
                      <SelectInput
                        label="Format"
                        source="format"
                        choices={FORMATS_SELECTION}
                        validate={[required()]}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                )}
                <TranslationHint
                  id={props.id}
                  format={formData.dataformat}
                  name={formData.name}
                />
              </>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </>
  );
};
