import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typo from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useDataProvider, Link } from "react-admin";
import mailgo from "mailgo";
// import TranslatorIcon from "@material-ui/icons/Translate";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
  ol: {
    lineHeight: "1.85rem",
  },
  inlineIcon: {
    fontSize: "1.2rem",
    verticalAlign: "text-bottom",
  },
  header: {
    marginBottom: "0.5rem",
  },
  mainHeader: {
    textAlign: "center",
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
  },
  card: {
    textAlign: "center",
    [theme.breakpoints.up("xs")]: {
      marginBottom: "40px",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
      height: "100%",
    },
  },
  cardValue: {
    fontSize: "1.3rem",
    fontWeight: 600,
  },
  usageInfo: {
    paddingTop: "40px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  cardRecommend: {
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
      marginTop: "10px",
    },
  },
  cardCLI: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
    },
  },
  danger_button: {
    color: red[900],
  },
}));

/*
const currency_symbols = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};
*/

// Configure mailgo for mailto links
mailgo({ showFooter: false });

const ManagePage = () => {
  const [usageData, setUsageData] = useState<{ plan: any; usage: any }>();
  const [userData, setUserData] = useState<{ id: string; email: string }>();
  const dataProvider = useDataProvider();

  useEffect(() => {
    // Request plan & user data
    const requestUsageInfo = async () => {
      try {
        const { data } = await dataProvider.getOne("usages", { id: "info" });
        setUsageData(data);
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    };

    const requestUserInfo = async () => {
      try {
        const { data } = await dataProvider.getOne("users", { id: "me" });
        setUserData(data);
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    };

    requestUsageInfo();
    requestUserInfo();
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  if (!usageData || !userData) return null;

  const { usage, plan } = usageData;
  const formatNumber = (num: number) => {
    const language =
      navigator && navigator.language ? navigator.language : "en-US";
    return new Intl.NumberFormat(language, {}).format(num);
  };

  return (
    <>
      {plan.isDefault && <Redirect to="/upgrade" />}

      {!plan.isDefault && (
        <Grid container>
          <Grid item xs={12}>
            <Typo variant="h4" className={classes.mainHeader}>
              {plan.name} ({plan.paddle_status})
            </Typo>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent>
                <Typo variant="h5" className={classes.header}>
                  Monthly quota
                </Typo>
                <p className={classes.cardValue}>
                  Segment: {formatNumber(plan.maxSegment)}
                  <br />
                  {
                    /* Remove when no user has subscription with documents */
                    plan.maxDocument > 0 && (
                      <>
                        Document: {formatNumber(plan.maxDocument)}
                        <br />
                      </>
                    )
                  }
                  No Code Usage: {formatNumber(plan.maxNocode)}
                </p>
                <p>
                  {plan.paddle_status === "active" && (
                    <Link className={classes.a} to="/manageUpgrade">
                      Change Plan
                    </Link>
                  )}{" "}
                  {plan.paddle_status !== "active" && (
                    <a className={classes.a} href="mailto:info@simpleen.io">
                      Please contact us to change subscription.
                    </a>
                  )}
                </p>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent>
                <Typo variant="h5" className={classes.header}>
                  {plan.paddle_next_billd_date
                    ? "Next bill amount"
                    : "Previously billed amount"}
                </Typo>
                <p className={classes.cardValue}>
                  <>
                    {/*plan.paddle_currency
                      ? currency_symbols[plan.paddle_currency as "USD"]
                    : null*/}
                    $ {plan.paddle_unit_price}
                  </>
                </p>
                {plan.paddle_update_url && (
                  <a className={classes.a} href={plan.paddle_update_url}>
                    Update billing info
                  </a>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent>
                <Typo variant="h5" className={classes.header}>
                  Next bill date
                </Typo>
                <p className={classes.cardValue}>
                  {plan.paddle_next_bill_date &&
                    new Date(plan.paddle_next_bill_date).toLocaleDateString(
                      undefined,
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                </p>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} className={classes.usageInfo}>
            <Typo variant="h5" className={classes.header}>
              Your usage (last 30 days)
            </Typo>
            <b>{formatNumber(usage.segment)}</b> Segments (excluding numbers,
            null and empty arrays)
            <br />
            <b>{formatNumber(usage.document)}</b> Documents
            <br />
            <b>{formatNumber(usage.nocode)}</b> Usages in No Code workflows
            <br />
            <br />
            <br />
            <br />
            {plan.endDate && (
              <Grid item xs={12} className={classes.usageInfo}>
                <p>
                  Your subscription is available until{" "}
                  <b>
                    {new Date(plan.endDate).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </b>
                </p>
              </Grid>
            )}
            {plan.paddle_cancel_url && (
              <Button
                variant="outlined"
                href={plan.paddle_cancel_url}
                className={classes.danger_button}
              >
                Cancel subscription
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ManagePage;
