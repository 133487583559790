import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Features from "./pricing/features";
import FAQ from "./pricing/faq";
import { PriceEstimator } from "./pricing/PriceEstimator";

type PricesProps = {
  user: {
    id: string;
    email: string;
  };
  buy: (
    subscriptionId: number,
    userId: string,
    email: string
  ) => (event: any) => void;
  currentPlan: null | { maxSegment: number; maxNocode: number };
};

const formatNumber = (num: number) => {
  const language =
    navigator && navigator.language ? navigator.language : "en-US";
  return new Intl.NumberFormat(language, {}).format(num);
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
  inclTax: {
    textAlign: "center",
    marginTop: "-20px",
    marginBottom: "20px",
  },
}));

export const Prices = (props: PricesProps) => {
  const classes = useStyles();

  const { currentPlan } = props;

  const getButtonLabel = (
    planSegment: number | undefined,
    maxSegment: number
  ) => {
    if (!planSegment) {
      return "Subscribe";
    }
    if (planSegment === maxSegment) {
      return "Current";
    }
    if (planSegment < maxSegment) {
      return "Upgrade";
    } else {
      return "Downgrade";
    }
  };

  const isDisabled = (planSegment: number | undefined, maxSegment: number) => {
    if (!planSegment) {
      return false;
    }
    if (planSegment === maxSegment) {
      return true;
    }
    return false;
  };

  const getButtonVariant = (
    planSegment: number | undefined,
    maxSegment: number
  ) => {
    // Subscribe page, without current subscription
    if (!planSegment && maxSegment === 10000) {
      return "contained";
    }
    if (planSegment === 1000 && maxSegment === 3000) {
      return "contained";
    }
    if (planSegment === 3000 && maxSegment === 10000) {
      return "contained";
    }
    if (planSegment === 10000 && maxSegment === 30000) {
      return "contained";
    }
    return "outlined";
  };

  const noCodeTier = {
    subscriptionId: 649701,
    title: "No Code Plan",
    price: "10",
    buttonText: getButtonLabel(currentPlan?.maxNocode, 500),
    buttonVariant: "outlined",
    disabled: isDisabled(currentPlan?.maxNocode, 500),
    description: [
      <>
        <b>{formatNumber(300)}</b> No Code Calls
        <br />
      </>,
      <>Translate with Integromat or Zapier</>,
    ],
  };

  const tiers = [
    {
      subscriptionId: 642906,
      title: "Starter",
      price: "15",
      maxSegments: 1000,
      description: [
        <>
          <b>{formatNumber(1000)}</b> Text Segments
          <br />
        </>,
        <>
          <b>Unlimited</b> Languages
          <br />
        </>,
        <>
          <b>All</b> Formats & Libraries
          <br />
        </>,
        <>
          <b>Email</b> Support
        </>,
      ],
      buttonText: getButtonLabel(currentPlan?.maxSegment, 1000),
      buttonVariant: getButtonVariant(currentPlan?.maxSegment, 1000),
      disabled: isDisabled(currentPlan?.maxSegment, 1000),
    },
    {
      subscriptionId: 641332,
      title: "Small",
      price: "35",
      maxSegments: 3000,
      description: [
        <>
          <b>{formatNumber(3000)}</b> Text Segments
        </>,
        <>
          <b>Unlimited</b> Languages
          <br />
        </>,
        <>
          <b>All</b> Formats & Libraries
          <br />
        </>,
        <>
          <b>Email</b> Support
        </>,
      ],
      buttonText: getButtonLabel(currentPlan?.maxSegment, 3000),
      buttonVariant: getButtonVariant(currentPlan?.maxSegment, 3000),
      disabled: isDisabled(currentPlan?.maxSegment, 3000),
    },
    {
      subscriptionId: 641333,
      title: "Medium",
      price: "75",
      maxSegments: 10000,
      description: [
        <>
          <b>{formatNumber(10000)}</b> Text Segments
        </>,
        <>
          <b>Unlimited</b> Languages
          <br />
        </>,
        <>
          <b>All</b> Formats & Libraries
          <br />
        </>,
        <>
          <b>Email</b> Support
        </>,
      ],
      buttonText: getButtonLabel(currentPlan?.maxSegment, 10000),
      buttonVariant: getButtonVariant(currentPlan?.maxSegment, 10000),
      disabled: isDisabled(currentPlan?.maxSegment, 10000),
    },
    {
      subscriptionId: 641334,
      title: "Large",
      price: "145",
      maxSegments: 30000,
      description: [
        <>
          <b>{formatNumber(25000)}</b> Text Segments
        </>,
        <>
          <b>Unlimited</b> Languages
          <br />
        </>,
        <>
          <b>All</b> Formats & Libraries
          <br />
        </>,
        <>
          <b>Email</b> Support
        </>,
      ],
      buttonText: getButtonLabel(currentPlan?.maxSegment, 30000),
      buttonVariant: getButtonVariant(currentPlan?.maxSegment, 30000),
      disabled: isDisabled(currentPlan?.maxSegment, 30000),
    },
  ];

  return (
    <>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Simple Monthly Plan
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          Choose a plan that fits your project needs.
          <br /> Change or cancel anytime.
        </Typography>
      </Container>
      {/* End hero unit */}

      <Container maxWidth="lg" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={3}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={tier.title === "Medium" ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /month
                    </Typography>
                    <br />
                  </div>
                  <Typography variant="body1" className={classes.inclTax}>
                    incl. VAT
                  </Typography>
                  <ul>
                    {tier.description.map((line, idx) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={idx}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant as any}
                    color="primary"
                    onClick={props.buy(
                      tier.subscriptionId,
                      props.user.id,
                      props.user.email
                    )}
                    disabled={tier.disabled}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12}></Grid>

          <Grid item md={5}></Grid>
          <Grid item md={2}>
            {/* No Code Plan*/}
            <Container
              maxWidth="sm"
              component="main"
              className={classes.heroContent}
            >
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                component="p"
              >
                {noCodeTier.title}
                <br />
              </Typography>
              <div className={classes.cardPricing}>
                <Typography component="h2" variant="h3" color="textPrimary">
                  ${noCodeTier.price}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  /month
                </Typography>
              </div>
              <Typography variant="body1" align="center" component="p">
                {noCodeTier.description.map((line, i) => (
                  <span key={i}>{line}</span>
                ))}
                <br />
                <br />
                <Button
                  fullWidth
                  variant={noCodeTier.buttonVariant as any}
                  color="primary"
                  onClick={props.buy(
                    noCodeTier.subscriptionId,
                    props.user.id,
                    props.user.email
                  )}
                  disabled={noCodeTier.disabled}
                >
                  {noCodeTier.buttonText}
                </Button>
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12} />

          <br />
          <br />
          <br />
          <br />
          <br />
          <PriceEstimator plans={tiers} />
          <Grid item xs={12}></Grid>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Features />
          <br />
          <br />
          <FAQ />
          <br />
        </Grid>
      </Container>
    </>
  );
};
