import React, {
  Children,
  cloneElement,
  isValidElement,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/RemoveCircle";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useTranslate, ValidationError } from "ra-core";
import classNames from "classnames";
import { FormInput } from 'react-admin'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      "& > li:last-child": {
        borderBottom: "none",
      },
    },
    line: {
      display: "flex",
      listStyleType: "none",

      [theme.breakpoints.down("xs")]: {
        display: "block",
        borderBottom: `solid 1px ${theme.palette.divider}`,
      },
      "&.fade-enter": {
        opacity: 0.01,
        transform: "translateX(100vw)",
      },
      "&.fade-enter-active": {
        opacity: 1,
        transform: "translateX(0)",
        transition: "all 500ms ease-in",
      },
      "&.fade-exit": {
        opacity: 1,
        transform: "translateX(0)",
      },
      "&.fade-exit-active": {
        opacity: 0.01,
        transform: "translateX(100vw)",
        transition: "all 500ms ease-in",
      },
    },
    form: { 
        flex: 2, 
        [theme.breakpoints.up("md")]: {
            display: "inline-flex"
        }
    },
    action: {
      //paddingTop: "0.5em",
    },
    leftIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
  }),
  { name: "RaCustomFormIterator" }
);

const CustomFormIterator = (props: any) => {
  const {
    basePath,
    children,
    fields,
    meta: { error, submitFailed },
    record,
    resource,
    source,
    disableAdd,
    disableRemove,
    // variant,
    // margin,
    TransitionProps,
    defaultValue,
  } = props;
  const translate = useTranslate();

  const classes = useStyles(props);

  // We need a unique id for each field for a proper enter/exit animation
  // so we keep an internal map between the field position and an auto-increment id
  const nextId = useRef(
    fields && fields.length
      ? fields.length
      : defaultValue
      ? defaultValue.length
      : 0
  );

  // We check whether we have a defaultValue (which must be an array) before checking
  // the fields prop which will always be empty for a new record.
  // Without it, our ids wouldn't match the default value and we would get key warnings
  // on the CssTransition element inside our render method
  const ids = useRef(
    nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
  );

  const removeField = (index: number) => () => {
    ids.current.splice(index, 1);
    fields.remove(index);
  };

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determining if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  const disableRemoveField = (record: any, disableRemove: any)=> {
    if (typeof disableRemove === "boolean") {
      return disableRemove;
    }
    return disableRemove && disableRemove(record);
  };

  const addField = () => {
    ids.current.push(nextId.current++);
    fields.push(undefined);
  };

  const records = get(record, source);
  return fields ? (
    <ul className={classes.root}>
      {submitFailed && typeof error !== "object" && error && (
        <FormHelperText error>
          <ValidationError error={error} />
        </FormHelperText>
      )}
      <TransitionGroup component={null}>
        {fields.map((member: any, index: number) => (
          <CSSTransition
            key={ids.current[index]}
            timeout={500}
            classNames="fade"
            {...TransitionProps}
          >
            <li className={classes.line}>
              <section className={classes.form}>
                {Children.map(children, (input: any, index2) =>
                  isValidElement(input) ? (
                    <FormInput
                      // @ts-ignore
                      basePath={input.props.basePath || basePath}
                      input={cloneElement(input, {
                        // @ts-ignore
                        source: input.props.source
                          ? 
                          // @ts-ignore
                            `${member}.${input.props.source}`
                          : member,
                        // @ts-ignore
                        index: input.props.source ? undefined : index2,
                        label:
                          // @ts-ignore
                          typeof input.props.label === "undefined"
                            ? 
                            // @ts-ignore
                              input.props.source
                              ? 
                              // @ts-ignore
                                `resources.${resource}.fields.${input.props.source}`
                              : undefined
                            : 
                            // @ts-ignore
                            input.props.label
                            
                      })}
                      record={(records && records[index]) || {}}
                      resource={resource}
                      variant={"outlined"} // variant
                      margin={"dense"} // margin
                      // helperText="" // set default to zero, no space is required until error is shown
                    />
                  ) : null
                )}
              </section>
              {!disableRemoveField(
                (records && records[index]) || {},
                disableRemove
              ) && (
                <span className={classes.action}>
                  <Button
                    className={classNames(
                      "button-remove",
                      `button-remove-${source}-${index}`
                    )}
                    size="small"
                    onClick={removeField(index)}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    {translate("ra.action.remove")}
                  </Button>
                </span>
              )}
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
      {!disableAdd && (
        <li className={classes.line}>
          <span className={classes.action}>
            <Button
              className={classNames("button-add", `button-add-${source}`)}
              size="small"
              onClick={addField}
            >
              <AddIcon className={classes.leftIcon} />
              {translate("ra.action.add")}
            </Button>
          </span>
        </li>
      )}
    </ul>
  ) : null;
};

CustomFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};

CustomFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  TransitionProps: PropTypes.shape({}),
};

export default CustomFormIterator;
