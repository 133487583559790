import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typo from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-admin";
import mailgo from "mailgo";
// import TranslatorIcon from "@material-ui/icons/Translate";
import Grid from "@material-ui/core/Grid";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ConfirmEmailPage from "./ConfirmEmailPage";

const useStyles = makeStyles((theme) => ({
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
  ol: {
    lineHeight: "1.85rem",
  },
  inlineIcon: {
    fontSize: "1.2rem",
    verticalAlign: "text-bottom",
  },
  header: {
    marginBottom: "0.5rem",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
    },
  },
  cardRecommend: {
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
      marginTop: "10px",
    },
  },
  cardCLI: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
    },
  },
}));

// Configure mailgo for mailto links
mailgo({ showFooter: false });

const Dashboard = ({ permissions }: any) => {
  const classes = useStyles();
  return permissions && !permissions.confirmed ? (
    <ConfirmEmailPage permissions={permissions} />
  ) : (
    <>
      <Grid container>
        <Grid item md={12}>
          <Typo
            variant="h5"
            style={{ textAlign: "center", marginBottom: "15px" }}
          >
            How to Translate your Software/i18n Project?
          </Typo>
          <Typo
            variant="body1"
            style={{ textAlign: "center", marginBottom: "15px" }}
          >
            There are different ways that you can use Simpleen to translate and
            localize your software: <br /> with the Online Translator, locally
            with the CLI (continuous translation) or custom use through the API.
          </Typo>
        </Grid>

        {/* Web Translator */}
        <Grid item md={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typo variant="h5" className={classes.header}>
                Online Translator
              </Typo>
              Fast and easy machine translation of i18n locales and
              documentations in your browser.
              <br />
              <br />
              We currently support the following formats:
              <ul>
                <li>HTML</li>
                <li>JSON</li>
                <li>Markdown</li>
                <li>Java Properties</li>
                <li>PHP Arrays</li>
                <li>YAML</li>
              </ul>
              Choose translator to localize <strong>or</strong> translate by
              format <strong>or</strong> create your own custom translator to
              reuse.
              <br />
              <br />
              <Grid container justify="center">
                <Grid item>
                  <Link to="/translatorsx">
                    <Button color="primary" variant="contained">
                      Choose translator
                    </Button>
                  </Link>
                </Grid>

                <Grid item>
                  <Link to="/translators/create">
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ marginLeft: "60px" }}
                    >
                      Create custom Translator
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* RECOMMENDATION CARD */}
          <Card className={classes.cardRecommend}>
            <CardContent>
              <Typography variant="h5" className={classes.header}>
                Get in touch with us
              </Typography>
              We're here to help you
              <br />
              <ul>
                <li>
                  Built by{" "}
                  <a
                    href="https://twitter.com/denisaugsburger"
                    rel="noopener norefferer"
                    className={classes.a}
                  >
                    @denisaugsburger
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://twitter.com/ciliawernli"
                    rel="noopener norefferer"
                    className={classes.a}
                  >
                    @ciliawernli
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/simpleen_io"
                    rel="noopener norefferer"
                    className={classes.a}
                  >
                    @simpleen_io
                  </a>
                </li>
              </ul>
              Email us:{" "}
              <a className={classes.a} href="mailto:info@simpleen.io">
                info@simpleen.io
              </a>
              <br />
            </CardContent>
          </Card>
        </Grid>

        {/* Simpleen CLI Tool */}
        <Grid item md={6}>
          <Card className={classes.cardCLI}>
            <CardContent>
              <Typo variant="h5" className={classes.header}>
                CLI Tool
              </Typo>
              Automate translation as part of your localization process. Benefit
              from up-to-date translations in your development workflow. It's
              compatible with various project structures.
              <br />
              <br />
              With the CLI Tool you can do the following:
              <ul>
                <li>
                  Machine translate multiple JSON files directly within your
                  project
                </li>
                <li>Upload existing & adapted translations</li>
              </ul>
              Simply install the npm-package: <br />
              <SyntaxHighlighter language="bash" style={atomOneLight}>
                {`yarn add -D simpleen
# or globally
yarn global add simpleen`}
              </SyntaxHighlighter>
              <Typo variant="body1" style={{ textAlign: "center" }}>
                Compatible Files (Properties, PO, JSON, YAML)
              </Typo>
              <img
                src="./CLIJSONLibraries.png"
                alt="compatible JSON libraries"
                style={{ display: "block", margin: "0 auto", width: "450px" }}
              />
              <br />
              <br />
              <Grid container justify="center">
                <Grid item>
                  <Link to="/integration">
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ marginRight: "40px" }}
                    >
                      Authentication Key
                    </Button>
                  </Link>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    href="https://simpleen.io/cli-documentation"
                  >
                    CLI Documentation
                  </Button>
                </Grid>
              </Grid>
              <br />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
