import React, { useState } from "react";
import { useQuery, useMutation, useNotify, Loading, Error } from "react-admin";
import { v4 as uuidv4 } from "uuid";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";

export function TokenGenerator() {
  const notify = useNotify();

  const { data, loading, error } = useQuery({
    type: "getList",
    resource: "tokens",
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "updated_at", order: "DESC" },
      filter: {},
    },
  });

  const [init, setInit] = useState(true);

  const [tokenData, setData] = useState<any>({});

  const [mutate, { loading: loadingMutate }] = useMutation();

  const saveToken = (data: any) => {
    if (data.type === "update") {
      // update
      return mutate(
        {
          type: "update",
          resource: "tokens",
          payload: {
            id: data.id,
            data: { token: data.token },
          },
        },
        {
          onSuccess: ({ data: updatedData }: any) => {
            setData(updatedData);
          },
          onFailure: (error: any) =>
            notify(
              `Could not update Authentication-Key. Error: ${error.message}`,
              "warning"
            ),
        }
      );
    }
    // Create
    return mutate(
      {
        type: "create",
        resource: "tokens",
        payload: {
          data: { token: data.token },
        },
      },
      {
        onSuccess: ({ data: updatedData }: any) => {
          setData(updatedData);
        },
        onFailure: (error: any) =>
          notify(
            `Could not create Authentication-Key. Error: ${error.message}`,
            "warning"
          ),
      }
    );
  };

  if (loading || loadingMutate) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  if (data && data[0] && data[0].token !== tokenData.token && init) {
    setData(data[0]);
    setInit(false);
  }

  return (
    <Grid container>
      <Grid item>
        <Input
          style={{ width: "380px" }}
          disabled
          value={tokenData && tokenData.token ? tokenData.token : ""}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                title="Copy Authentication-Key"
                aria-label="Copy Authentication-Key"
              >
                <IconButton
                  aria-label="copy authentication key"
                  onClick={() => {
                    navigator.clipboard.writeText(tokenData.token);
                    notify("Copied Authentication-Key in clipboard", "info");
                  }}
                >
                  {tokenData && tokenData.token ? <CopyIcon /> : null}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item style={{ paddingLeft: "40px" }}>
        <Button
          variant="outlined"
          color="primary"
          disabled={loadingMutate}
          onClick={() => {
            // Generate token
            const tempToken = uuidv4();

            // Save in backend
            saveToken({
              token: tempToken,
              type: tokenData && tokenData.id ? "update" : "create",
              id: tokenData.id,
            });
          }}
        >
          {tokenData && tokenData.id
            ? "Replace with new API-Key"
            : "Generate API-Key"}
        </Button>
      </Grid>
    </Grid>
  );
}
