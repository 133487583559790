import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

type TranslationProps = {
  id?: null | string;
  format: string;
  name?: string;
};

const useStyles = makeStyles((theme) => ({
  a: {
    textDecoration: "none",
    color: "#0f4c81",
    padding: "0 4px",
  },
}));

export const TranslationHint: React.FunctionComponent<TranslationProps> = ({
  format,
  id,
  name,
}) => {
  const classes = useStyles();
  let command;
  let apiURL = `${process.env.REACT_APP_STRAPI_API}/translators/${
    id ? id : "{id}"
  }/translate`;

  switch (format) {
    case "HTML": {
      command = (
        <CodeBlock
          text={`
                    /* Post-Request to use your translator for translations */
                    axios.post("${apiURL}", 
                    {
                        // Body attributes with text property (HTML format)
                        text: "<h1>My super header</h1><p class='hint'>Start your translations</p>"
                    },
                    options: {
                        params: {
                          auth_key: "API_KEY"
                        }
                    });
                `}
          language="typescript"
          theme={dracula}
        />
      );
      break;
    }
    case "JSON": {
      // hint = `Post-Endpoint for translators: ${apiURL}`;
      command = (
        <CodeBlock
          text={`
                    /* Post-Request to use your translator for translations */
                    axios.post("${apiURL}", 
                    {
                        // You can select which part of the JSON should be translated via selections
                        // i. e. header -> Text and body -> HTML, no selection of slug, but it will be returned untouched from the API
                        text: '{"slug": "should not be translated", "header": "Header to translate", "body": "<h1 class=\\"myTest\\">Body combined with HTML</h1><p>This text will be translated</p>"}',
                    },
                    options: {
                        params: {
                          auth_key: "API_KEY"
                        }
                    });
                `}
          language="typescript"
          theme={dracula}
        />
      );

      break;
    }
    case "Markdown": {
      command = (
        <CodeBlock
          text={`
                    /* Post-Request to use your translator for translations  */
                    axios.post("${apiURL}", 
                    {
                        // Body attributes with text property (Markdown format)
                        text: "# Header to translate\\nStart your translations with [link](https://simpleen.io)"
                    },
                    options: {
                        params: {
                          auth_key: "API_KEY"
                        }
                    });
                `}
          language="typescript"
          theme={dracula}
        />
      );
      break;
    }
    case "Zapier": {
      command = (
        <CodeBlock
          text={`
                    /* Select the name ${name} in your Zapier account */
                    // You can connect up to 3 text with different formats via Zapier
                `}
          language="typescript"
          theme={dracula}
        />
      );
      break;
    }
    default: {
      return null;
    }
  }

  return (
    <div style={{ width: "100%", margin: "1em" }}>
      <Typography variant="h6">Usage Hints</Typography>
      <Accordion>
        <AccordionSummary>Online Translator</AccordionSummary>
        <AccordionDetails>
          Use your configured translator by copy & pasting your content directly
          to
          <Link className={classes.a} to="/translate">
            {" "}
            translate
          </Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Workflow Automation (CLI)</AccordionSummary>
        <AccordionDetails>
          <CodeBlock
            text={`
# Create Authentication Key

# Install CLI in project
yarn add -D simpleen

# configure project - insert Authentication Key
yarn run simpleen init

# translate input files to target files
yarn run simpleen translate
          `}
            language="shell"
            theme={dracula}
          ></CodeBlock>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>No Code Automation</AccordionSummary>
        <AccordionDetails>
          The translation processes can be configured directly in your workflow
          in{" "}
          <a
            href="https://www.integromat.com/en/integrations/simpleen-translation"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.a}
          >
            Integromat
          </a>{" "}
          or{" "}
          <a
            href="https://zapier.com/apps/simpleen-translation/integrations"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.a}
          >
            Zapier
          </a>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Implementing with API</AccordionSummary>
        <AccordionDetails>{command}</AccordionDetails>
      </Accordion>
    </div>
  );
};
