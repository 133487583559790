import React from "react";
import { Title } from "react-admin";
import { TokenGenerator } from "./TokenGenerator";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneLight } from "react-syntax-highlighter/dist/esm/styles/hljs";

const useStyles = makeStyles((theme) => ({
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
  inlineIcon: {
    fontSize: "1.2rem",
    verticalAlign: "text-bottom",
  },
  card: {
    marginBottom: "30px",
  },
}));

const Integration = () => {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.card}>
        <Title title="Integrate in your workflow" />
        <CardContent>
          <Typography variant="h5">Authentication Key</Typography>
          Create an authentication key to use Simpleen directly within your
          project (CLI) as well as the API.
          <br />
          <br />
          <TokenGenerator />
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5">Use the Simpleen CLI</Typography>
          Install the npm-package: <br />
          <SyntaxHighlighter language="bash" style={atomOneLight}>
            {`yarn add -D simpleen 
# or globally
yarn global add simpleen`}
          </SyntaxHighlighter>
          Use the authentication key above in the `simpleen init` command. You
          can run the local installed version with:
          <SyntaxHighlighter language="bash" style={atomOneLight}>
            {`
# Configure project, add authentication key
yarn run simpleen init
# Save existing translations
yarn run simpleen upload
# Translate all matched input files to target languages
yarn run simpleen translate
`}
          </SyntaxHighlighter>
          You can also add Simpleen as a script in your package.json and combine
          it with an extractor of your i18n library, for example with Format.JS:
          <SyntaxHighlighter language="json" style={atomOneLight}>
            {`{
...
"scripts": {
  "translate": "simpleen translate",
  "extractTranslate": "formatjs extract --format simple && simpleen translate"
}}`}
          </SyntaxHighlighter>
          See{" "}
          <a
            href="https://simpleen.io/documentation/cli-reference"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.a}
          >
            CLI Documentation
          </a>{" "}
          for detailed information.
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5">Use the API</Typography>
          If you like to use the API directly see{" "}
          <a
            href="https://simpleen.io/documentation/api-reference"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.a}
          >
            API Documentation
          </a>{" "}
          for more info.
        </CardContent>
      </Card>
    </>
  );
};

export default Integration;
