import React from 'react';
import { Edit, SimpleForm, TextInput, PasswordInput, required, minLength } from "react-admin";

const ProfileEdit = ({ staticContext, ...props }: any) => {
  
  const validatePasswords = (values: any) => {
    const errors: any = {};
    if (values.password && values.password !== values.passwordRepeat) {
      errors.passwordRepeat = ['The provided passwords are not identical']
    }
    return errors
  };

  return (
      <Edit
        /*
        As this component isn't loaded from a route generated by a <Resource>,
        I have to provide the id myself.
        As there is only one config for the current user, I decided to
        hard-code it here
      */
        undoable={false}
        // This ID must match with the ID provided by the data
        id="profile"
        resource="profile"
        basePath="/profile" // Redirect, not used really because redirect is disabled
        redirect={false} // I don't need any redirection here, there's no list page
        title="My Profile"
        {...props}
      >
        <SimpleForm validate={validatePasswords}>
          <TextInput source="email" disabled />
          <PasswordInput source="password" label="New password" validate={[required(), minLength(8, "Password should be at least 8 characters long")]} />
          <PasswordInput source="password" name="passwordRepeat" label="Repeat Password" validate={[required()]} />
        </SimpleForm>
      </Edit>
  );
};

export default ProfileEdit;
