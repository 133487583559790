import React from "react";
import {
  Create,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  required,
  SelectInput,
} from "react-admin";
import {
  FORMATS,
  FORMATS_SELECTION,
  FORMALITIES,
  INTERPOLATIONS,
} from "./constants";
import { TranslationHint } from "./TranslationHint";

export const TranslatorCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="/translatorsx">
        <TextInput source="name" validate={[required()]} />

        <ReferenceInput
          label="Source Language *"
          source="source_language"
          reference="languages"
          perPage={125}
          sort={{ field: "name", order: "ASC" }}
          filter={{ source: true }}
          validate={[required()]}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>

        <ReferenceInput
          label="Target Language *"
          source="target_language"
          perPage={125}
          reference="languages"
          sort={{ field: "name", order: "ASC" }}
          filter={{ target: true }}
          validate={[required()]}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>

        <SelectInput
          source="formality"
          choices={FORMALITIES}
          defaultValue="default"
        />

        <SelectInput
          source="interpolation"
          choices={INTERPOLATIONS}
          defaultValue="default"
          label="Interpolation (variables)"
        />

        <ReferenceInput
          label="Glossary"
          source="glossary"
          reference="glossaries"
          sort={{ field: "name", order: "ASC" }}
          validate={[]}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>

        <SelectInput
          source="dataformat"
          choices={FORMATS}
          validate={[required()]}
          defaultValue="JSON"
        />

        <FormDataConsumer>
          {({ formData, ...rest }: any) => (
            <>
              {formData.dataformat === "JSON" && (
                <ArrayInput
                  source="selections"
                  label="Selections (optional - defaults up to 5 levels with HTML)"
                >
                  <SimpleFormIterator>
                    <TextInput
                      label="JSON Path"
                      source="path"
                      validate={[required()]}
                    />
                    <SelectInput
                      label="Format"
                      source="format"
                      choices={FORMATS_SELECTION}
                      validate={[required()]}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              )}
              <TranslationHint
                id={props.id}
                format={formData.dataformat}
                name={formData.name}
              />
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
