import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typo from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TranslatorOption } from "./TranslatorOption";
import { httpClient } from "../../../authentication/authProvider";
import Loader from "react-loader-spinner";
import { TranslatorFormat } from "../../translator/constants";

const useStyles = makeStyles((theme: Theme) => ({
  title: { textAlign: "center", marginBottom: "15px" },

  subTitle: {
    marginTop: "1.25rem",
    marginBottom: "0.75rem",
  },
}));

const apiUrl = process.env.REACT_APP_STRAPI_API;

const initTranslators = () => {
  return httpClient(apiUrl + "/translators?_sort=name:ASC");
};

const getImageFromformat = (format: TranslatorFormat) => {
  const images = {
    HTML: "./libraries/html.png",
    Markdown: "./libraries/markdown.png",
    PHPArray: "./libraries/php.png",
    Properties: "./libraries/spring.png",
    PO: "./libraries/po.png",
    JSON: "./libraries/json.png",
    YAML: "./libraries/yaml.png",
  };

  return images[format] ? images[format] : "";
};

export const TranslatorsOverview = () => {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);

  const [translators, setTranslators] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    // ts-ignore
    initTranslators()
      .then((result: any) => setTranslators(result.json))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typo variant="h5" className={classes.subTitle}>
            Custom Translator{translators && translators.length > 1 && "s"}
          </Typo>
        </Grid>

        <TranslatorOption
          key="custom"
          title="Custom"
          image="https://image.shutterstock.com/image-vector/plus-icon-flat-design-style-260nw-589199351.jpg"
          linkTo="/translators/create"
          description="Create a custom translator from scratch to reuse"
          callToAction="Create"
        />

        {isLoading && (
          <>
            <Loader
              type="Rings"
              color="#00BFFF"
              height={35}
              width={35}
              // @ts-ignore
              style={{ height: "35px" }}
            />
            Load custom translators...
          </>
        )}

        {translators &&
          translators.map((translator) => (
            <TranslatorOption
              key={translator.id}
              image={getImageFromformat(translator.dataformat)}
              title={translator.name}
              callToAction="Translate"
              linkTo={`/translate-custom/${translator.id}`}
              description={`Custom ${translator.dataformat} Translator ${translator.source_language.language}=>${translator.target_language.language}`}
              id={translator.id}
            />
          ))}

        <Grid item xs={12}>
          <Typo
            variant="h5"
            className={classes.subTitle}
            style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}
          >
            Localize by i18n Library/Framework
          </Typo>
        </Grid>

        <TranslatorOption
          key="formatjs"
          title="Format.JS / react-intl"
          image="./libraries/formatjs.png"
          linkTo="/translate/formatjs"
          description="Internationalize your web apps on the client & server."
        />

        <TranslatorOption
          key="i18next"
          title="i18next"
          image="./libraries/i18next.png"
          linkTo="/translate/i18next"
          description="I18next is an internationalization-framework written in and for JavaScript."
        />

        <TranslatorOption
          key="laravel"
          title="Laravel"
          image="./libraries/laravel.png"
          linkTo="/translate/laravel"
          description="The PHP Framework for Web Artisans"
        />

        <TranslatorOption
          key="linguijs"
          title="Lingui.JS"
          image="./libraries/linguijs.png"
          linkTo="/translate/linguijs"
          description="Seamless internationalization in Javascript"
        />

        <TranslatorOption
          key="ngxtranslate"
          title="ngx-translate"
          image="./libraries/ngx.png"
          linkTo="/translate/ngxtranslate"
          description="The internationalization (i18n) library for Angular"
        />

        <TranslatorOption
          key="polyglot"
          title="Polyglot.JS"
          image="./libraries/polyglot.png"
          linkTo="/translate/polyglot"
          description="Give your JavaScript the ability to speak many languages"
        />

        <TranslatorOption
          key="python"
          title="Python-i18n"
          image="./libraries/python.png"
          linkTo="/translate/python"
          description="This library provides i18n functionality for Python 3 out of the box."
        />

        <TranslatorOption
          key="pythonyaml"
          title="Python-i18nYAML"
          image="./libraries/pythonyaml.png"
          linkTo="/translate/pythonYAML"
          description="This library provides i18n functionality for Python 3 out of the box."
        />

        <TranslatorOption
          key="rubyonrails"
          title="Ruby on Rails"
          image="./libraries/rails.png"
          linkTo="/translate/rubyonrails"
          description="Internationalization (i18n) library for Ruby (YAML)"
        />

        <TranslatorOption
          key="svelte"
          title="Svelte i18n"
          image="./libraries/svelte.png"
          linkTo="/translate/sveltejs"
          description="Internationalization library for Svelte"
        />

        <TranslatorOption
          key="symfony"
          title="Symfony"
          image="./libraries/symfony.png"
          linkTo="/translate/symfony"
          description="PHP framework for web projects"
        />

        <TranslatorOption
          key="transloco"
          title="Transloco"
          image="./libraries/transloco.png"
          linkTo="/translate/transloco"
          description="The internationalization (i18n) library for Angular"
        />

        <TranslatorOption
          key="vuei18n"
          title="Vue I18n"
          image="./libraries/vuei18n.png"
          linkTo="/translate/vuei18n"
          description="Vue I18n is internationalization plugin for Vue.js"
        />

        <TranslatorOption
          key="yii"
          title="Yii"
          image="./libraries/yii.png"
          linkTo="/translate/yii"
          description="Yii is a fast, secure, and efficient PHP framework with i18n support"
        />

        <Grid item xs={12}>
          <Typo variant="h5" className={classes.subTitle}>
            Translate by Format
          </Typo>
        </Grid>

        <TranslatorOption
          key="html"
          title="HTML"
          image={getImageFromformat("HTML")} // "https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg"
          linkTo="/translate/html"
          description="Translate HTML documentation and posts"
        />

        <TranslatorOption
          key="markdown"
          title="Markdown"
          image={getImageFromformat("Markdown")}
          linkTo="/translate/markdown"
          description="Translate Markdown documentation and posts"
        />

        <TranslatorOption
          key="php"
          title="PHP Arrays"
          image={getImageFromformat("PHPArray")}
          linkTo="/translate/phparray"
          description="Translate PHP Arrays"
        />

        <TranslatorOption
          key="PO"
          title="PO/MO Files"
          image={getImageFromformat("PO")}
          linkTo="/translate/po"
          description="Translate PO/MO Files"
        />

        <TranslatorOption
          key="properties"
          title="Java Properties"
          image={getImageFromformat("Properties")}
          linkTo="/translate/properties"
          description="Translate Java properties"
        />

        <TranslatorOption
          key="json"
          title="JSON"
          image={getImageFromformat("JSON")}
          linkTo="/translate/json"
          description="Translate JSON data and locales"
        />

        <TranslatorOption
          key="yaml"
          title="YAML"
          image={getImageFromformat("YAML")}
          linkTo="/translate/yaml"
          description="Translate YAML data and locales"
        />
      </Grid>
    </>
  );
};
