import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import { FORMATS, FORMALITIES, INTERPOLATIONS } from "../translator/constants";

export const FileEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />

      <TextInput source="filePath" />

      <SelectInput
        source="dataformat"
        choices={FORMATS}
        validate={[required()]}
        defaultValue="JSON"
      />

      <ReferenceInput
        label="Source Language *"
        source="source_language.id"
        reference="languages"
        sort={{ field: "name", order: "ASC" }}
        filter={{ source: true }}
        validate={[required()]}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <ReferenceArrayInput
        label="Target Languages *"
        source="target_languages"
        reference="languages"
        sort={{ field: "name", order: "ASC" }}
        filter={{ target: true }}
        validate={[required()]}
        format={(arr: any) => {
          // API returns prefilled language objects
          if (arr && arr.length) {
            // Transform API response to array of ids
            return arr.map((v: any) => {
              if (v && v.id) {
                return v.id;
              }
              return v;
            });
          }
          return arr;
        }}
        /*parse={(v: any) => {
          return v;
        }}*/
        // optionText="language"
        // optionValue="id"
      >
        <SelectArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>

      <SelectInput
        source="interpolation"
        choices={INTERPOLATIONS}
        defaultValue="default"
        label="Interpolation (variables)"
      />

      <SelectInput
        source="formality"
        choices={FORMALITIES}
        defaultValue="default"
      />
    </SimpleForm>
  </Edit>
);
