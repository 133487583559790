import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { Link } from "ra-ui-materialui";
import { Typography } from "@material-ui/core";

const SegmentFilter = (props: any) => (
  <Filter sort={{ field: "entry", order: "ASC" }} {...props}>
    <TextInput label="Search" source="q" alwaysOn />

    <ReferenceInput
      label="Target Language"
      source="target_language"
      reference="languages"
      sort={{ field: "name", order: "ASC" }}
      filter={{ target: true }}
      suggestionLimit={50}
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput
      label="File"
      source="file"
      reference="files"
      sort={{ field: "name", order: "ASC" }}
      suggestionLimit={50}
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
  </Filter>
);

const LinkEditField = ({
  source,
  record = {},
}: {
  source: string;
  record?: any;
}) => {
  if (record[source]) {
    return (
      <Link
        style={{ zIndex: 99999 }}
        to={`/files/${record[source]["id"]}`}
        onClick={(e: any) => e.stopPropagation()}
      >
        {record[source]["name"]}
      </Link>
    );
  }
  return null;
};

export const SegmentList = (props: any) => (
  <>
    <List {...props} filters={<SegmentFilter />} exporter={false}>
      <Datagrid rowClick="edit">
        {/*
      
      <TextField source="path" />
      */}
        <TextField source="entry" />
        <TextField source="source_entry" />

        <TextField label="Source Language" source="source_language.name" />
        <TextField label="Target Language" source="target_language.name" />

        <LinkEditField source="file" />

        {/*
      <TextField source="interpolation" />
      <TextField source="formality" />
      */}
      </Datagrid>
    </List>
    <div
      style={{
        flex: "1 1 800px",
        width: "100%",
        margin: "1em",
        visibility: props.data ? "visible" : "hidden",
      }}
    >
      <Typography variant="h6">Segments</Typography>
      <Typography variant="body2">
        Segments are automatically created during the translation process.{" "}
        <br />
        You can edit them for future translations.
      </Typography>
    </div>
  </>
);
