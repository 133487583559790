import React, { useEffect, useState } from "react";
import { useMutation } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typo from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import mailgo from "mailgo";
// import TranslatorIcon from "@material-ui/icons/Translate";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
  ol: {
    lineHeight: "1.85rem",
  },
  inlineIcon: {
    fontSize: "1.2rem",
    verticalAlign: "text-bottom",
  },
  header: {
    marginBottom: "0.5rem",
    fontSize: "1.7rem",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
    },
  },
  cardRecommend: {
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
      marginTop: "10px",
    },
  },
  cardCLI: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
    },
  },
}));

// Configure mailgo for mailto links
mailgo({ showFooter: false });

const ConfirmEmailPage = (props: {
  permissions: { confirmed: boolean; email: string };
}) => {
  const classes = useStyles();
  const { permissions } = props;
  const [time, setTime] = useState(5000);

  const [sendEmail, { loading, error }] = useMutation({
    type: "create",
    resource: "auth/send-email-confirmation",
    payload: { data: { email: permissions.email } },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(0);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  return (
    <>
      <Grid container>
        {/* Web Translator */}
        <Grid item md={3} />
        <Grid item md={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typo
                variant="h5"
                style={{ textAlign: "center" }}
                className={classes.header}
              >
                Verify your email to finish signing up for Simpleen
              </Typo>

              <br />
              <Grid container justify="center">
                <Grid item xs={12}>
                  <p style={{ textAlign: "center" }}>
                    Thank you for choosing Simpleen. <br />
                    We've sent an email to <strong>
                      {permissions.email}
                    </strong>{" "}
                    so you can confirm your account.
                  </p>
                </Grid>

                <Grid item xs={12}>
                  <p style={{ textAlign: "center" }}>
                    You didn't get the email?
                  </p>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{}}
                    onClick={() => {
                      setTime(10000);
                      sendEmail();
                    }}
                    disabled={loading || time > 0}
                  >
                    Send Email again
                  </Button>
                </Grid>
                <br />
                <br />
                <br />
                <Grid item xs={12}>
                  {error && <Alert severity="error">{error.message}</Alert>}
                  <br />
                </Grid>
                <Grid item>
                  <br />
                  Not your email?{" "}
                  <Link href="/signup">
                    <Button color="primary" variant="text">
                      Create new account
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* RECOMMENDATION CARD */}
          <Card className={classes.cardRecommend}>
            <CardContent>
              <Typography variant="h5" className={classes.header}>
                Get in touch with us
              </Typography>
              We're here to help you
              <br />
              <ul>
                <li>
                  Built by{" "}
                  <a
                    href="https://twitter.com/denisaugsburger"
                    rel="noopener norefferer"
                    className={classes.a}
                  >
                    @denisaugsburger
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://twitter.com/ciliawernli"
                    rel="noopener norefferer"
                    className={classes.a}
                  >
                    @ciliawernli
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/simpleen_io"
                    rel="noopener norefferer"
                    className={classes.a}
                  >
                    @simpleen_io
                  </a>
                </li>
              </ul>
              Email us:{" "}
              <a className={classes.a} href="mailto:info@simpleen.io">
                info@simpleen.io
              </a>
              <br />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmEmailPage;
