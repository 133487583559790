export type Choice = {
  id: string;
  name: string | number;
  disabled?: boolean;
};

export const FORMATS: Choice[] = [
  { id: "HTML", name: "HTML" },
  { id: "JSON", name: "JSON" },
  { id: "Markdown", name: "Markdown" },
  { id: "Properties", name: "Java Properties" },
  { id: "PHPArray", name: "PHP Array" },
  { id: "PO", name: "PO" },
  { id: "YAML", name: "YAML (without imports)" },
  // { id: "XML", name: "XML (Coming soon)", disabled: true },
  // temporary remove, until we've got a zap to select translators
  // { id: "Zapier", name: "Zapier" },
];

export type TranslatorFormat =
  | "HTML"
  | "JSON"
  | "Markdown"
  | "Properties"
  | "PHPArray"
  | "PO"
  | "YAML";

export type Interpolations =
  | "default"
  | "polyglot"
  | "i18next"
  | "i18n"
  | "ruby"
  | "laravel"
  | "sprintf"
  | "icu"
  | "none";

export const FORMATS_SELECTION: Choice[] = [
  { id: "HTML", name: "HTML" },
  { id: "Markdown", name: "Markdown" },
  { id: "Text", name: "Text" },
  { id: "XML", name: "XML" },
];

export const FORMALITIES: Choice[] = [
  { id: "default", name: "Automatic" },
  { id: "less", name: "Informal tone" },
  { id: "more", name: "Formal tone" },
];

export type Formality = "default" | "less" | "more";

export const INTERPOLATIONS: Choice[] = [
  { id: "default", name: "Automatic" },
  { id: "polyglot", name: "%{ variable }" },
  { id: "i18next", name: "{{ variable }}" },
  { id: "i18n", name: "{ variable }" },
  { id: "icu", name: "ICU Messages { variable, number, ::currency/USD }" },
  // eslint-disable-next-line
  { id: "ruby", name: "${ variable }" },
  { id: "laravel", name: ":variable" },
  { id: "sprintf", name: "%variable" },
  { id: "none", name: "None" },
];

export type Selection = { path: string; format: string };

export type Translator = {
  id: number;
  name: string;
  dataformat: TranslatorFormat;
  glossary:
    | {
        id: string;
        name: string;
      }
    | undefined;
  source_language: Language;
  target_language: Language;
  selections: Selection[];
  formality: "default" | "more" | "less";
  interpolation: "default" | "i18next" | "polyglot" | "i18n" | "icu";
};

export type Language = {
  id: string;
  name: string;
  language: string;
  isRtl?: boolean;
};

export type Glossary = {
  id: string;
  name: string;
};
