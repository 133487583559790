import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDataProvider, Link } from "react-admin";
import useInterval from "react-useinterval";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  number: {
    fontWeight: "bold",
  },
  numberExhausted: {
    fontWeight: "bold",
    color: "red",
  },
  smallDiv: {
    paddingLeft: "5px",
  },
  smallView: {
    fontSize: "0.7rem",
  },
  smallButton: {
    // fontSize: "0.5rem",
    // padding: "0 0"
  },
}));

type UsageProps = {
  sidebarIsOpen?: boolean;
  className?: string;
};

export const UsageComponent = ({ sidebarIsOpen, className }: UsageProps) => {
  const classes = useStyles();
  const [usageData, setUsageData] = useState<{ plan: any; usage: any }>();
  const dataProvider = useDataProvider();

  const requestInfo = async () => {
    try {
      const { data } = await dataProvider.getOne("usages", { id: "info" });
      setUsageData(data);
    } catch (e) {
      console.log(JSON.stringify(e));
    }
  };

  useEffect(() => {
    const requestFirstInfo = async () => {
      try {
        const { data } = await dataProvider.getOne("usages", { id: "info" });
        setUsageData(data);
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    };

    requestFirstInfo();
  }, [dataProvider]);
  useInterval(requestInfo, 5000);

  if (!usageData) return null;

  const { usage, plan } = usageData;
  const isExhausted = (num: number, maxNum: number) => {
    return num >= maxNum;
  };

  const normalView = (
    <div className={className}>
      <p>
        <b>{plan.name} Usage</b>
        <br />

        <div>
          <Tooltip
            title="Translated values of data structure"
            aria-label="String values"
            placement="top-start"
          >
            <span>Segment: </span>
          </Tooltip>
          <span
            className={
              isExhausted(usage.segment, plan.maxSegment)
                ? classes.numberExhausted
                : classes.number
            }
          >
            {usage.segment} / {plan.maxSegment}
          </span>
        </div>

        {plan.maxDocument > 0 && (
          <div>
            <Tooltip
              title="Not used anymore. All formats support now text segments for caching"
              placement="top-start"
            >
              <span> Document: </span>
            </Tooltip>
            <span
              className={
                isExhausted(usage.document, plan.maxDocument)
                  ? classes.numberExhausted
                  : classes.number
              }
            >
              {usage.document} / {plan.maxDocument}
            </span>
          </div>
        )}

        <div>
          <Tooltip
            title="Calls initiated from Integromat or Zapier"
            placement="top-start"
          >
            <span>No Code Usage: </span>
          </Tooltip>
          <span
            className={
              isExhausted(usage.nocode, plan.maxNocode)
                ? classes.numberExhausted
                : classes.number
            }
          >
            {usage.nocode} / {plan.maxNocode}
          </span>
        </div>
      </p>
      <Link to={plan.isDefault ? "/upgrade" : "/manage"}>
        <Button
          variant={plan.isDefault ? "contained" : "outlined"}
          color="primary"
        >
          {plan.isDefault ? "Get more" : "Manage"}
        </Button>
      </Link>
    </div>
  );

  const minimizedView = null; /* (
    <div className={cx(className, classes.smallDiv)}>
      <p className={classes.smallView}>
        <b>{plan.name}</b>
        <br />S<br />
        <span
          className={
            isExhausted(usage.segment, plan.maxSegment)
              ? classes.numberExhausted
              : classes.number
          }
        >
          {usage.segment}/{plan.maxSegment}
        </span>
        <br />
        D<br />
        <span
          className={
            isExhausted(usage.document, plan.maxDocument)
              ? classes.numberExhausted
              : classes.number
          }
        >
          {usage.document}/{plan.maxDocument}
        </span>
        <br />
        N<br />{" "}
        <span
          className={
            isExhausted(usage.nocode, plan.maxNocode)
              ? classes.numberExhausted
              : classes.number
          }
        >
          {usage.nocode}/{plan.maxNocode}
        </span>
        <br />
      </p>
      <IconButton
        aria-label="manage"
        href="/#/manage"
        className={classes.smallButton}
      >
        <UpgradeIcon color="primary" aria-label="manage" />
      </IconButton>
        </div>
  );*/

  return sidebarIsOpen ? normalView : minimizedView;
};
