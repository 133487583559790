import React from "react";
import { useDelete, Link } from "react-admin";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu/Menu";
import TextIcon from "@material-ui/icons/TextFields";

type TranslatorOptionProps = {
  callToAction?: string;
  description: string;
  image?: string;
  title: string;
  linkTo: string;
  id?: string;
};

export const TranslatorOption = (props: TranslatorOptionProps) => {
  const { callToAction, title, description, image, linkTo, id } = props;

  const [deleteOne, { loading, error }] = useDelete(
    "translators",
    id as string
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteOne();
    handleMenuClose();
    window && window.location && window.location.reload();
  };

  if (loading) {
    return (
      <Grid item xs={6} md={4} lg={3}>
        Deleting...
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid item xs={6} md={4} lg={3}>
        Could not delete Translator - {JSON.stringify(error)};
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={6}
      md={4}
      lg={3}
      style={{
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        padding: "10px",
        //marginRight: "10px",
        marginBottom: "10px",
      }}
    >
      <Grid
        container
        justify="space-between"
        direction="column"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Grid
            container
            alignItems="center"
            justify={id ? "space-between" : "flex-start"}
          >
            {image && image !== "textIcon" && (
              <Grid item>
                <img src={image} height="50px" alt={`logo ${title}`} />
              </Grid>
            )}
            {image === "textIcon" && (
              <Grid item>
                <TextIcon />
              </Grid>
            )}

            <Grid item>
              <Typography
                variant="h6"
                style={{ paddingLeft: "10px", fontSize: "1rem" }}
              >
                {title}
              </Typography>
            </Grid>
            {id && (
              <Grid item>
                <IconButton
                  onClick={handleMenuOpen}
                  aria-label="translator option"
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="simple-edit"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <Link to={`/translators/${id}`}>
                    <MenuItem onClick={handleMenuClose}>Edit</MenuItem>
                  </Link>

                  <MenuItem onClick={handleDelete} style={{ color: "#0f4c81" }}>
                    Delete
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <p>{description}</p>
        </Grid>
        <Grid item>
          <Link to={linkTo}>
            <Button
              style={{ alignSelf: "flex-end" }}
              variant="contained"
              color="primary"
            >
              {callToAction ? callToAction : "Translate"}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
