import React from "react";
import { List, Datagrid, TextField, FunctionField } from "react-admin";

export const GlossaryList = (props: any) => (
         <List sort={{ field: "name", order: "ASC" }} {...props}>
           <Datagrid rowClick="edit">
             <TextField source="id" />
             <TextField source="name" />
             <FunctionField
               label="Entries"
               render={(record: any) => record?.entries?.length}
             />
             <FunctionField
               label="Ignored"
               render={(record: any) => record?.ignored?.length}
             />
             {/*
             <FunctionField
               label="UsedBy"
               render={(record: any) => record?.translators?.length}
             />
             */
             }
           </Datagrid>
         </List>
       );
